export const UPLOAD_CROP_TYPE_LIST_REQUEST = 'UPLOAD_CROP_TYPE_LIST_REQUEST';
export const UPLOAD_CROP_TYPE_LIST_SUCCESS = 'UPLOAD_CROP_TYPE_LIST_SUCCESS';
export const UPLOAD_CROP_TYPE_LIST_FAIL = 'UPLOAD_CROP_TYPE_LIST_FAIL';
export const UPLOAD_CROP_TYPE_LIST_RESET = 'UPLOAD_CROP_TYPE_LIST_RESET';

export const GET_NEW_USERS_REQUEST = 'GET_NEW_USERS_REQUEST';
export const GET_NEW_USERS_SUCCESS = 'GET_NEW_USERS_SUCCESS';
export const GET_NEW_USERS_FAIL = 'GET_NEW_USERS_FAIL';
export const GET_NEW_USERS_RESET = 'GET_NEW_USERS_RESET';

export const AUTHORISE_NEW_USER_REQUEST = 'AUTHORISE_NEW_USER_REQUEST';
export const AUTHORISE_NEW_USER_SUCCESS = 'AUTHORISE_NEW_USER_SUCCESS';
export const AUTHORISE_NEW_USER_SAVE = 'AUTHORISE_NEW_USER_SAVE';
export const AUTHORISE_NEW_USER_FAIL = 'AUTHORISE_NEW_USER_FAIL';
export const AUTHORISE_NEW_USER_RESET = 'AUTHORISE_NEW_USER_RESET';

export const UPDATE_CROP_REQUEST = 'UPDATE_CROP_REQUEST';
export const UPDATE_CROP_SUCCESS = 'UPDATE_CROP_SUCCESS';
export const UPDATE_CROP_FAIL = 'UPDATE_CROP_FAIL';
export const UPDATE_CROP_RESET = 'UPDATE_CROP_RESET';

export const GET_AUTHORISED_USERS_REQUEST = 'GET_AUTHORISED_USERS_REQUEST';
export const GET_AUTHORISED_USERS_SUCCESS = 'GET_AUTHORISED_USERS_SUCCESS';
export const GET_AUTHORISED_USERS_FAIL = 'GET_AUTHORISED_USERS_FAIL';
export const GET_AUTHORISED_USERS_RESET = 'GET_AUTHORISED_USERS_RESET';

export const GET_REGISTERED_USERS_REQUEST = 'GET_REGISTERED_USERS_REQUEST';
export const GET_REGISTERED_USERS_SUCCESS = 'GET_REGISTERED_USERS_SUCCESS';
export const GET_REGISTERED_USERS_FAIL = 'GET_REGISTERED_USERS_FAIL';
export const GET_REGISTERED_USERS_RESET = 'GET_REGISTERED_USERS_RESET';

export const GET_REGISTERED_USERS_FILTER = 'GET_REGISTERED_USERS_FILTER';
export const GET_REGISTERED_USERS_CLEAR = 'GET_REGISTERED_USERS_CLEAR';

export const SIGNIN_ADDRESS_REQUEST = 'SIGNIN_ADDRESS_REQUEST';
export const SIGNIN_ADDRESS_SUCCESS = 'SIGNIN_ADDRESS_SUCCESS';
export const SIGNIN_ADDRESS_FAIL = 'SIGNIN_ADDRESS_FAIL';
export const SIGNIN_ADDRESS_RESET = 'SIGNIN_ADDRESS_RESET';

export const GET_CURRENT_USER_LOCATION_REQUEST =
    'GET_CURRENT_USER_LOCATION_REQUEST';
export const GET_CURRENT_USER_LOCATION_SUCCESS =
    'GET_CURRENT_USER_LOCATION_SUCCESS';
export const GET_CURRENT_USER_LOCATION_FAIL = 'GET_CURRENT_USER_LOCATION_FAIL';
export const GET_CURRENT_USER_LOCATION_RESET =
    'GET_CURRENT_USER_LOCATION_RESET';

export const UPDATE_CURRENT_USER_LOCATION_REQUEST =
    'UPDATE_CURRENT_USER_LOCATION_REQUEST';
export const UPDATE_CURRENT_USER_LOCATION_SUCCESS =
    'UPDATE_CURRENT_USER_LOCATION_SUCCESS';
export const UPDATE_CURRENT_USER_LOCATION_FAIL =
    'UPDATE_CURRENT_USER_LOCATION_FAIL';
export const UPDATE_CURRENT_USER_LOCATION_RESET =
    'UPDATE_CURRENT_USER_LOCATION_RESET';

export const GET_CURRENT_SALES_POSTS_REQUEST =
    'GET_CURRENT_SALES_POSTS_REQUEST';
export const GET_CURRENT_SALES_POSTS_SUCCESS =
    'GET_CURRENT_SALES_POSTS_SUCCESS';
export const GET_CURRENT_SALES_POSTS_FAIL = 'GET_CURRENT_SALES_POSTS_FAIL';
export const GET_CURRENT_SALES_POSTS_RESET = 'GET_CURRENT_SALES_POSTS_RESET';

export const UPDATE_CURRENT_SALES_POSTS_REQUEST =
    'UPDATE_CURRENT_SALES_POSTS_REQUEST';
export const UPDATE_CURRENT_SALES_POSTS_SUCCESS =
    'UPDATE_CURRENT_SALES_POSTS_SUCCESS';
export const UPDATE_CURRENT_SALES_POSTS_FAIL =
    'UPDATE_CURRENT_SALES_POSTS_FAIL';
export const UPDATE_CURRENT_SALES_POSTS_RESET =
    'UPDATE_CURRENT_SALES_POSTS_RESET';

export const GET_CLAIMS_REQUEST = 'GET_CLAIMS_REQUEST';
export const GET_CLAIMS_SUCCESS = 'GET_CLAIMS_SUCCESS';
export const GET_CLAIMS_FAIL = 'GET_CLAIMS_FAIL';
export const GET_CLAIMS_RESET = 'GET_CLAIMS_RESET';

export const UPDATE_CLAIMS_REQUEST = 'UPDATE_CLAIMS_REQUEST';
export const UPDATE_CLAIMS_SUCCESS = 'UPDATE_CLAIMS_SUCCESS';
export const UPDATE_CLAIMS_FAIL = 'UPDATE_CLAIMS_FAIL';
export const UPDATE_CLAIMS_RESET = 'UPDATE_CLAIMS_RESET';

export const CHECK_IF_USER_EXISTS_REQUEST = 'CHECK_IF_USER_EXISTS_REQUEST';
export const CHECK_IF_USER_EXISTS_SUCCESS = 'CHECK_IF_USER_EXISTS_SUCCESS';
export const CHECK_IF_USER_EXISTS_FAIL = 'CHECK_IF_USER_EXISTS_FAIL';
export const CHECK_IF_USER_EXISTS_RESET = 'CHECK_IF_USER_EXISTS_RESET';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL';
export const SEND_EMAIL_RESET = 'SEND_EMAIL_RESET';

export const ADD_MEETINGS_REQUEST = 'ADD_MEETINGS_REQUEST';
export const ADD_MEETINGS_SUCCESS = 'ADD_MEETINGS_SUCCESS';
export const ADD_MEETINGS_FAIL = 'ADD_MEETINGS_FAIL';
export const ADD_MEETINGS_RESET = 'ADD_MEETINGS_RESET';

export const DELETE_MEETING_REQUEST = 'DELETE_MEETING_REQUEST';
export const DELETE_MEETING_SUCCESS = 'DELETE_MEETING_SUCCESS';
export const DELETE_MEETING_FAIL = 'DELETE_MEETING_FAIL';
export const DELETE_MEETING_RESET = 'DELETE_MEETING_RESET';
