import { USER_BUYER, USER_SELLER } from '../constants/userTypeConstants';
import { GROWER_GENERAL_PRODUCT, getMessageContent } from './messageContent';

export function getUserPath(uid, userType) {
    return `/Users/${
        userType.isFarmer
            ? 'Farmers'
            : userType.isDriver
            ? 'Drivers'
            : userType.isTrader
            ? 'Traders'
            : null
    }/${uid}`;
}

export function getUserPathFromInt(uid, userType) {
    return `/Users/${
        userType === 1
            ? 'Farmers'
            : userType === 2
            ? 'Drivers'
            : userType === 3
            ? 'Traders'
            : null
    }/${uid}`;
}

export function getLocationPath(uid, userType) {
    return `${
        userType.isFarmer
            ? '/Farms/farmLocations'
            : userType.isDriver
            ? '/ServiceLocations/depots'
            : userType.isTrader
            ? '/Markets/marketplaces'
            : null
    }/${uid}`;
}

export function getUserPostsPath(uid, userType) {
    const val = userType.isFarmer
        ? `/Users/Farmers/${uid}/SalesPosts/`
        : userType.isDriver
        ? `/Users/Drivers/${uid}/DeliveryPosts/`
        : userType.isTrader
        ? `/Users/Traders/${uid}/ProcurementPosts/`
        : null;

    return val;
}

export function getSinglePostPath(pid, userType) {
    const val = userType.isFarmer
        ? `/SalesPosts/${pid}`
        : userType.isDriver
        ? `/DeliveryPosts/${pid}`
        : userType.isTrader
        ? `/ProcurementPosts/${pid}`
        : null;

    return val;
}

export function getPostsPath(userType) {
    if (typeof userType === 'string') {
        const val =
            userType === USER_SELLER
                ? `/SalesPosts/`
                : userType === 'drivers'
                ? `/DeliveryPosts/`
                : userType === USER_BUYER
                ? `/ProcurementPosts/`
                : null;

        return val;
    } else {
        const val = userType.isFarmer
            ? `/SalesPosts/`
            : userType.isDriver
            ? `/DeliveryPosts/`
            : userType.isTrader
            ? `/ProcurementPosts/`
            : null;

        return val;
    }
}

export function getGradingRequestPath(userType) {
    const val = userType.isFarmer
        ? '/AI/gradingRequest/'
        : userType.isDriver
        ? '/AI/driverLabels/'
        : userType.isTrader
        ? '/AI/procurementRequest/'
        : null;

    return val;
}

export function getTrainingPath(userType) {
    const val = userType.isFarmer
        ? '/AI/ProductsTraining/'
        : userType.isDriver
        ? '/AI/DeliveriesTraining/'
        : userType.isTrader
        ? '/AI/ProcurementTraining/'
        : null;

    return val;
}

export function getAdminEditProductPath(product) {
    const val = `/admin-product-edit/${
        product.farmerId ? product.farmerId : product.traderId
    }/${product._id}/${product.farmerId ? '1' : '3'}`;

    return val;
}

export function getFeedbackPath(productId) {
    const val = `/product/${productId}/give-grading-advice`;

    return val;
}

export function getMessageUserPath(product, messageContentType) {
    const message = getMessageContent(product, messageContentType);
    const messagePath = `/message-centre/new-message/${
        product.farmerId ? product.farmerId : product.traderId
    }?mt=${
        product.farmerId ? 'contact-grower' : 'contact-trader'
    }&message=${encodeURI(message)}`;

    return messagePath;
}

export function getModelPath(code) {
    const val = `/AI/models/${code}`;
    return val;
}

export function getNotificationTokenPath(token) {
    const val = `/Notifications/Tokens/${token}`;
    return val;
}

export function getUserTokensPath(uid, userType) {
    const val = userType.isFarmer
        ? `/Users/Farmers/${uid}/Notifications/Tokens/`
        : userType.isDriver
        ? `/Users/Drivers/${uid}/Notifications/Tokens/`
        : userType.isTrader
        ? `/Users/Traders/${uid}/Notifications/Tokens/`
        : null;

    return val;
}

export function getUserNotificationsPath(uid) {
    const val = `/Notifications/Users/${uid}`;
    return val;
}

export function getSafeSenderPath(uid, userType) {
    const val = userType.isFarmer
        ? `/SafeSenders/farmerAuthorisation/${uid}`
        : userType.isDriver
        ? `/SafeSenders/farmerAuthorisation/${uid}`
        : userType.isTrader
        ? `/SafeSenders/traderPermissions/${uid}`
        : null;

    return val;
}

export function getArchivePath(pid) {
    return `/Archived/${pid}`;
}
