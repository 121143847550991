import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Redirect, Route, useLocation } from 'react-router-dom';
import LoadingBox from '../LoadingBox/LoadingBox';

export default function PrivateRoute({ component: Component, ...rest }) {
    const { currentUser, loading } = useAuth();
    const location = useLocation();
    return (
        <Route
            {...rest}
            render={(props) =>
                !loading ? (
                    currentUser ? (
                        <Component {...props}></Component>
                    ) : (
                        <Redirect
                            to={
                                location
                                    ? `/signin?redirect=${location.pathname}`
                                    : '/signin'
                            }
                        />
                    )
                ) : (
                    <LoadingBox>Loading...</LoadingBox>
                )
            }
        ></Route>
    );
}
