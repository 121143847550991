import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { cartReducer } from './firebase/reducers/cartReducers';
import { getFirebase, firebaseReducer } from 'react-redux-firebase';
import {
    productCreateReducer,
    productDeleteReducer,
    productDetailsReducer,
    productImageDeleteReducer,
    productImageListReducer,
    productUpdateReducer,
} from './firebase/reducers/productReducers';
import { cropTypesListReducer } from './firebase/reducers/cropTypeReducers';
import {
    manualProfileReducer,
    otherUserProfileReducer,
    userAddressMapReducer,
    otherUserLocationAddressReducer,
    userLocationAddressReducer,
    userProfileReducer,
    userSignoutReducer,
    userSignupRequestReducer,
    userUpdateReducer,
    userValidateReducer,
    userMeetingsReducer,
} from './firebase/reducers/userReducers';
import {
    addMeetingRequestReducer,
    authorisedUserListReducer,
    authoriseNewUserReducer,
    checkIfUserExistsReducer,
    currentUserLocationReducer,
    currentUserSalesPostsReducer,
    emailResponseReducer,
    meetingDeletionReducer,
    newUsersListReducer,
    queryClaimsReducer,
    registeredUserListReducer,
    signupLocationReducer,
    updateClaimsReducer,
    updateCurrentUserLocationReducer,
    updateCurrentUserSalesPostsReducer,
    updateSingleCropReducer,
    uploadCroptypeListReducer,
} from './firebase/reducers/adminReducers';
import { productImageUploadReducer } from './firebase/reducers/productReducers';
import {
    orderCreateReducer,
    orderDetailsReducer,
    orderListReducer,
    orderUpdateReducer,
} from './firebase/reducers/orderReducers';
import { signupLocationListReducer } from './firebase/reducers/locationReducers';
import {
    saveSwipesReducer,
    trainingDataListReducer,
    trainingImageUploadReducer,
    updateTrainingDataReducer,
} from './firebase/reducers/trainingReducers';
import {
    businessLogoReducer,
    userProfileImageReducer,
    businessInformationReducer,
} from './firebase/reducers/userProfileReducers';
import {
    chatListReducer,
    createChatReducer,
    messageListReducer,
    messageUploadReducer,
} from './firebase/reducers/chatReducers';
import { setupConfigReducer } from './firebase/reducers/setupReducers';
import {
    getGradingFeedbackReducer,
    gradingFeedbackUpdateReducer,
    moreGradingPhotosReducer,
} from './firebase/reducers/gradingReducers';
import {
    productListReducer,
    userProductListReducer,
} from './firebase/reducers/listingsReducers';
import { adminDashboardReducer } from './firebase/reducers/adminDashReducers';
import {
    modelReviewReducer,
    predictionReducer,
    preparedImageReducer,
} from './firebase/reducers/modelReducers';
import {
    currentVersionReducer,
    notificationListReducer,
    notificationsUpdateReducer,
    userTokenCreationReducer,
} from './firebase/reducers/notificationReducers';
import {
    addSenderReducer,
    safeSendersReducer,
} from './firebase/reducers/safeSenderReducers';
import { meetingsListReducer } from './firebase/reducers/meetingsReducer';
import { latestCurrenciesReducer } from './firebase/reducers/currenciesReducers';
const initialState = {
    setupConfig: {
        setup: localStorage.getItem('setupConfig')
            ? JSON.parse(localStorage.getItem('setupConfig'))
            : {
                  profileComplete: false,
                  addressComplete: false,
                  listingComplete: false,
                  dialogClosed: false,
                  renderCount: 0,
              },
    },
    cart: {
        cartItems: localStorage.getItem('cartItems')
            ? JSON.parse(localStorage.getItem('cartItems'))
            : [],
        shippingAddress: localStorage.getItem('shippingAddress')
            ? JSON.parse(localStorage.getItem('shippingAddress'))
            : {},
        paymentMethod: localStorage.getItem('paymentMethod')
            ? JSON.parse(localStorage.getItem('paymentMethod'))
            : 'COD',
        shippingMethod: localStorage.getItem('shippingMethod')
            ? JSON.parse(localStorage.getItem('shippingMethod'))
            : 'COD',
    },
    newUsersList: {},
    authorisedUserList: {},
    notificationList: {
        notifications: localStorage.getItem('backgroundNotifications')
            ? JSON.parse(localStorage.getItem('backgroundNotifications'))
            : [],
    },
};

const reducer = combineReducers({
    adminDashboard: adminDashboardReducer,
    authorisedUserList: authorisedUserListReducer,
    authoriseNewUser: authoriseNewUserReducer,
    businessInformation: businessInformationReducer,
    businessLogo: businessLogoReducer,
    cart: cartReducer,
    chatList: chatListReducer,
    checkIfUserExists: checkIfUserExistsReducer,
    createChat: createChatReducer,
    cropTypesList: cropTypesListReducer,
    currentUserLocation: currentUserLocationReducer,
    currentUserSalesPosts: currentUserSalesPostsReducer,
    currentVersion: currentVersionReducer,
    emailResponse: emailResponseReducer,
    firebase: firebaseReducer,
    getGradingFeedback: getGradingFeedbackReducer,
    gradingFeedbackUpdate: gradingFeedbackUpdateReducer,
    manualProfile: manualProfileReducer,
    messageList: messageListReducer,
    messageUpload: messageUploadReducer,
    modelReview: modelReviewReducer,
    moreGradingPhotos: moreGradingPhotosReducer,
    newUsersList: newUsersListReducer,
    notificationList: notificationListReducer,
    notificationsUpdate: notificationsUpdateReducer,
    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderList: orderListReducer,
    orderUpdate: orderUpdateReducer,
    otherUserProfile: otherUserProfileReducer,
    prediction: predictionReducer,
    preparedImage: preparedImageReducer,
    productCreate: productCreateReducer,
    productDelete: productDeleteReducer,
    productDetails: productDetailsReducer,
    productImageDelete: productImageDeleteReducer,
    productImageList: productImageListReducer,
    productImageUpload: productImageUploadReducer,
    productList: productListReducer,
    productUpdate: productUpdateReducer,
    queryClaims: queryClaimsReducer,
    registeredUserList: registeredUserListReducer,
    saveSwipes: saveSwipesReducer,
    setupConfig: setupConfigReducer,
    signupLocation: signupLocationReducer,
    signupLocationList: signupLocationListReducer,
    trainingDataList: trainingDataListReducer,
    trainingImageUpload: trainingImageUploadReducer,
    updateClaims: updateClaimsReducer,
    updateCurrentUserLocation: updateCurrentUserLocationReducer,
    updateCurrentUserSalesPosts: updateCurrentUserSalesPostsReducer,
    updateSingleCrop: updateSingleCropReducer,
    updateTrainingData: updateTrainingDataReducer,
    uploadCropTypeList: uploadCroptypeListReducer,
    userAddressMap: userAddressMapReducer,
    otherUserLocationAddress: otherUserLocationAddressReducer,
    userLocationAddress: userLocationAddressReducer,
    userProductList: userProductListReducer,
    userProfile: userProfileReducer,
    userProfileImage: userProfileImageReducer,
    userSignout: userSignoutReducer,
    userSignupRequest: userSignupRequestReducer,
    userTokenCreation: userTokenCreationReducer,
    userUpdate: userUpdateReducer,
    userValidate: userValidateReducer,
    addSender: addSenderReducer,
    safeSenders: safeSendersReducer,
    addMeetingRequest: addMeetingRequestReducer,
    meetingDeletion: meetingDeletionReducer,
    meetingsList: meetingsListReducer,
    userMeetings: userMeetingsReducer,
    latestCurrencies: latestCurrenciesReducer,
});

const middlewares = [thunk.withExtraArgument(getFirebase)];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(...middlewares))
);

export default store;
