import {
    GET_GRADING_FEEDBACK_FAIL,
    GET_GRADING_FEEDBACK_REQUEST,
    GET_GRADING_FEEDBACK_SUCCESS,
    REQUEST_MORE_PHOTOS_FAIL,
    REQUEST_MORE_PHOTOS_REQUEST,
    REQUEST_MORE_PHOTOS_RESET,
    REQUEST_MORE_PHOTOS_SUCCESS,
    UPDATE_GRADING_FEEDBACK_FAIL,
    UPDATE_GRADING_FEEDBACK_REQUEST,
    UPDATE_GRADING_FEEDBACK_RESET,
    UPDATE_GRADING_FEEDBACK_SUCCESS,
} from '../constants/gradingConstants';

export const gradingFeedbackUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_GRADING_FEEDBACK_REQUEST:
            return { loading: true };
        case UPDATE_GRADING_FEEDBACK_SUCCESS:
            return { loading: false, success: true };
        case UPDATE_GRADING_FEEDBACK_FAIL:
            return { loading: false, error: action.payload };
        case UPDATE_GRADING_FEEDBACK_RESET:
            return {};
        default:
            return state;
    }
};

export const getGradingFeedbackReducer = (
    state = { trainingData: [] },
    action
) => {
    switch (action.type) {
        case GET_GRADING_FEEDBACK_REQUEST:
            return { loading: true };
        case GET_GRADING_FEEDBACK_SUCCESS:
            return { loading: false, gradingFeedback: action.payload };
        case GET_GRADING_FEEDBACK_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const moreGradingPhotosReducer = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_MORE_PHOTOS_REQUEST:
            return { loading: true };
        case REQUEST_MORE_PHOTOS_SUCCESS:
            console.log('action.payload');
            console.log(action.payload);
            return {
                loading: false,
                message: action.payload.message,
                success: action.payload.success,
            };
        case REQUEST_MORE_PHOTOS_FAIL:
            return { loading: false, error: action.payload };
        case REQUEST_MORE_PHOTOS_RESET:
            return {};
        default:
            return state;
    }
};
