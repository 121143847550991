import {
    CART_ADD_ITEM,
    CART_ADD_ITEM_FAIL,
    CART_ADD_STANDING_ORDER,
    CART_EMPTY,
    CART_EMPTY_STANDING_ORDER,
    CART_REMOVE_ITEM,
    CART_SAVE_DISPATCH_ADDRESS,
    CART_SAVE_PAYMENT_METHOD,
    CART_SAVE_PERSONAL_DETAILS,
    CART_SAVE_PERSONAL_DISPATCH_DETAILS,
    CART_SAVE_SHIPPING_ADDRESS,
    CART_SAVE_SHIPPING_METHOD,
} from '../constants/cartConstants';

export const cartReducer = (state = { cartItems: [] }, action) => {
    switch (action.type) {
        case CART_ADD_ITEM:
            const item = action.payload;
            const existItem = state.cartItems.find((x) => x._id === item._id);
            if (existItem) {
                return {
                    ...state,
                    error: '',
                    cartItems: state.cartItems.map((x) =>
                        x._id === existItem._id ? item : x
                    ),
                };
            } else {
                return {
                    ...state,
                    error: '',
                    cartItems: [...state.cartItems, item],
                };
            }
        case CART_REMOVE_ITEM:
            return {
                ...state,
                error: '',
                cartItems: state.cartItems.filter(
                    (x) => x._id !== action.payload
                ),
            };
        case CART_SAVE_PERSONAL_DETAILS:
            return { ...state, shippingPersonalDetails: action.payload };
        case CART_SAVE_SHIPPING_ADDRESS:
            return { ...state, shippingAddress: action.payload };
        case CART_SAVE_PERSONAL_DISPATCH_DETAILS:
            return { ...state, dispatchPersonalDetails: action.payload };
        case CART_SAVE_DISPATCH_ADDRESS:
            return { ...state, dispatchAddress: action.payload };
        case CART_SAVE_SHIPPING_METHOD:
            return { ...state, shippingMethod: action.payload };
        case CART_SAVE_PAYMENT_METHOD:
            return { ...state, paymentMethod: action.payload };
        case CART_ADD_ITEM_FAIL:
            return { ...state, error: action.payload };
        case CART_EMPTY:
            return {
                ...state,
                error: '',
                cartItems: [],
                paymentMethod: '',
                dispatchAddress: '',
                shippingMethod: '',
            };
        case CART_ADD_STANDING_ORDER:
            return { ...state, standingOrder: action.payload };
        case CART_EMPTY_STANDING_ORDER:
            return {
                ...state,
                error: '',
                standingOrder: {},
                paymentMethod: '',
                dispatchAddress: '',
                shippingMethod: '',
            };
        default:
            return state;
    }
};
