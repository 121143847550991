import React from 'react';
import { Fab, Grid, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useStyles from './styles.js';

export default function CloseButton({ handleClose }) {
    const classes = useStyles();
    return (
        <Grid container spacing={4} maxWidth="lg">
            <Grid item xs={12} className={classes.close}>
                <Tooltip title="Close" arrow>
                    <Fab
                        sx={{ boxShadow: 0 }}
                        size="small"
                        aria-label="close"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </Fab>
                </Tooltip>
            </Grid>
        </Grid>
    );
}
