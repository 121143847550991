import {
    BUSINESS_INFORMATION_FAIL,
    BUSINESS_INFORMATION_REQUEST,
    BUSINESS_INFORMATION_RESET,
    BUSINESS_INFORMATION_SUCCESS,
    LOGO_UPLOAD_FAIL,
    LOGO_UPLOAD_REQUEST,
    LOGO_UPLOAD_RESET,
    LOGO_UPLOAD_SUCCESS,
    USER_IMAGE_UPLOAD_FAIL,
    USER_IMAGE_UPLOAD_REQUEST,
    USER_IMAGE_UPLOAD_RESET,
    USER_IMAGE_UPLOAD_SUCCESS,
} from '../constants/userProfileConstants';

export const userProfileImageReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_IMAGE_UPLOAD_REQUEST:
            return { loading: true };
        case USER_IMAGE_UPLOAD_SUCCESS:
            return { loading: false, url: action.payload };
        case USER_IMAGE_UPLOAD_FAIL:
            return { loading: false, error: action.payload };
        case USER_IMAGE_UPLOAD_RESET:
            return {};
        default:
            return state;
    }
};

export const businessLogoReducer = (state = {}, action) => {
    switch (action.type) {
        case LOGO_UPLOAD_REQUEST:
            return { loading: true };
        case LOGO_UPLOAD_SUCCESS:
            return { loading: false, url: action.payload };
        case LOGO_UPLOAD_FAIL:
            return { loading: false, error: action.payload };
        case LOGO_UPLOAD_RESET:
            return {};
        default:
            return state;
    }
};

export const businessInformationReducer = (state = {}, action) => {
    switch (action.type) {
        case BUSINESS_INFORMATION_REQUEST:
            return { loading: true };
        case BUSINESS_INFORMATION_SUCCESS:
            return { loading: false, business: action.payload };
        case BUSINESS_INFORMATION_FAIL:
            return { loading: false, error: action.payload };
        case BUSINESS_INFORMATION_RESET:
            return {};
        default:
            return state;
    }
};
