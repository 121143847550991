export const ADD_SENDER_LIST_REQUEST = 'ADD_SENDER_LIST_REQUEST';
export const ADD_SENDER_LIST_SUCCESS = 'ADD_SENDER_LIST_SUCCESS';
export const ADD_SENDER_LIST_FAIL = 'ADD_SENDER_LIST_FAIL';
export const ADD_SENDER_LIST_RESET = 'ADD_SENDER_LIST_RESET';

export const SENDER_LIST_REQUEST = 'SENDER_LIST_REQUEST';
export const SENDER_LIST_SUCCESS = 'SENDER_LIST_SUCCESS';
export const SENDER_LIST_FAIL = 'SENDER_LIST_FAIL';
export const SENDER_LIST_RESET = 'SENDER_LIST_RESET';

export const CHECK_AUTHORISED_REQUEST = 'CHECK_AUTHORISED_REQUEST';
export const CHECK_AUTHORISED_SUCCESS = 'CHECK_AUTHORISED_SUCCESS';
export const CHECK_AUTHORISED_FAIL = 'CHECK_AUTHORISED_FAIL';
export const CHECK_AUTHORISED_RESET = 'CHECK_AUTHORISED_RESET';
