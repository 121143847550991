import {
    PRODUCT_LIST_CLEAR,
    PRODUCT_LIST_FAIL,
    PRODUCT_LIST_FILTER,
    PRODUCT_LIST_FILTER_REQUEST,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_RESET,
    PRODUCT_LIST_SUCCESS,
    USER_PRODUCT_LIST_FAIL,
    USER_PRODUCT_LIST_REQUEST,
    USER_PRODUCT_LIST_RESET,
    USER_PRODUCT_LIST_SUCCESS,
} from '../constants/listingsConstants';

export const productListReducer = (
    state = { products: [], rawProducts: [] },
    action
) => {
    switch (action.type) {
        case PRODUCT_LIST_REQUEST:
            return { loading: true, buyerOrSeller: action.payload };
        case PRODUCT_LIST_SUCCESS:
            return {
                loading: false,
                loadingFilter: false,
                products: action.payload.products,
                rawProducts: action.payload.products,
                buyerOrSeller: action.payload.buyerOrSeller,
            };
        case PRODUCT_LIST_FILTER_REQUEST:
            return {
                ...state,
                loadingFilter: true,
            };
        case PRODUCT_LIST_FILTER:
            return {
                ...state,
                loading: false,
                loadingFilter: false,
                products: action.payload.products,
                buyerOrSeller: action.payload.buyerOrSeller,
            };
        case PRODUCT_LIST_CLEAR:
            return {
                ...state,
                loading: false,
                loadingFilter: false,
                products: state.rawProducts,
            };
        case PRODUCT_LIST_FAIL:
            return {
                loading: false,
                loadingFilter: false,
                error: action.payload,
            };
        case PRODUCT_LIST_RESET:
            return {
                products: [],
                rawProducts: [],
                loading: false,
                loadingFilter: false,
            };
        default:
            return state;
    }
};

export const userProductListReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case USER_PRODUCT_LIST_REQUEST:
            return { loading: true };
        case USER_PRODUCT_LIST_SUCCESS:
            return { loading: false, products: action.payload };
        case USER_PRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload };
        case USER_PRODUCT_LIST_RESET:
            return { products: [], loading: false };
        default:
            return state;
    }
};
