export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL';
export const ORDER_LIST_RESET = 'ORDER_LIST_RESET';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_IMAGE_URLS = 'ORDER_DETAILS_IMAGE_URLS';
export const ORDER_SAVE_CROP_DETAILS = 'ORDER_SAVE_CROP_DETAILS';
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL';
export const ORDER_DETAILS_RESET = 'ORDER_DETAILS_RESET';

export const ORDER_UPDATE_REQUEST = 'ORDER_UPDATE_REQUEST';
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS';
export const ORDER_UPDATE_FAIL = 'ORDER_UPDATE_FAIL';
export const ORDER_UPDATE_RESET = 'ORDER_UPDATE_RESET';
