import React from 'react';
import { Fab, Stack } from '@mui/material';

import StorefrontIcon from '@mui/icons-material/Storefront';
import { getUserLcString } from '../../functions/getUser';

export default function MyMarket({
    props,
    handleMenuClose,
    currentUser,
    userType,
}) {
    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ p: 2 }}
            onClick={() => {
                props.history.push(
                    `/profile/${getUserLcString(currentUser.uid, userType)}`
                );
                handleMenuClose();
            }}
        >
            <Fab sx={{ boxShadow: 0 }} size="small" aria-label="minus">
                <StorefrontIcon />
            </Fab>
            <p>My Market</p>
        </Stack>
    );
}
