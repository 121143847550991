import OneSignal from 'react-onesignal';
import { buyers, farmers } from './onesignal/tags';
import isSubscribed from './onesignal/isSubscribed';

export default async function runOneSignal(ut) {
    console.log('running OneSignal check init');
    console.log(ut);
    await OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
        allowLocalhostAsSecureOrigin: Boolean(
            process.env.REACT_APP_ONESIGNAL_SECURE_LOCAL
        ),
        promptOptions: {
            slidedown: {
                prompts: [
                    {
                        type: 'category',
                        autoPrompt: true,
                        text: {
                            actionMessage:
                                "We'd like to show you notifications for the latest news and updates.",
                            acceptButton: 'Allow',
                            cancelButton: 'Cancel',

                            /* CATEGORY SLIDEDOWN SPECIFIC TEXT */
                            negativeUpdateButton: 'Cancel',
                            positiveUpdateButton: 'Save Preferences',
                            updateMessage:
                                'Update your push notification subscription preferences.',
                        },
                        delay: {
                            pageViews: 1,
                            timeDelay: 20,
                        },
                        categories: ut.isFarmer
                            ? farmers
                            : ut.isTrader
                            ? buyers
                            : [],
                    },
                ],
            },
        },
    });

    // OneSignal.setExternalUserId(uid);
    const deviceSubscription = await isSubscribed();
    console.log('isSubscribed check after init: ' + deviceSubscription);
    if (!deviceSubscription) {
        OneSignal.showCategorySlidedown();
    } else {
        const onesignalPermission = await OneSignal.getSubscription();
        console.log('onesignalPermission: ' + onesignalPermission);
        if (!onesignalPermission) {
            await OneSignal.setSubscription(true);
        }
    }
}
