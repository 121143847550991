import { SAVE_SETUP_CONFIG } from '../constants/setupConstants';

export const setupConfigReducer = (state = {}, action) => {
    switch (action.type) {
        case SAVE_SETUP_CONFIG:
            return { ...state, setup: action.payload };

        default:
            return state;
    }
};
