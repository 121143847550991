import React from 'react';
import { IconButton, SvgIcon } from '@mui/material';
import theme from '../../../appTheme';

export default function TwitterIcon() {
    return (
        <IconButton
            color="secondary"
            sx={{ ':hover': { color: theme.palette.primary.main } }}
            onClick={() => {
                var win = window.open(
                    'https://twitter.com/kurongakurima',
                    '_blank'
                );
                win.focus();
            }}
        >
            <SvgIcon>
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                >
                    <title>Twitter</title>
                    <path d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
                </svg>
            </SvgIcon>
        </IconButton>
    );
}
