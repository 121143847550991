import {
    CHAT_CREATE_FAIL,
    CHAT_CREATE_REQUEST,
    CHAT_CREATE_RESET,
    CHAT_CREATE_SUCCESS,
    CHAT_LIST_FAIL,
    CHAT_LIST_REQUEST,
    CHAT_LIST_RESET,
    CHAT_LIST_SUCCESS,
    MESSAGE_LIST_FAIL,
    MESSAGE_LIST_REQUEST,
    MESSAGE_LIST_RESET,
    MESSAGE_LIST_SUCCESS,
    MESSAGE_SEEN,
    MESSAGE_SEND_FAIL,
    MESSAGE_SEND_REQUEST,
    MESSAGE_SEND_RESET,
    MESSAGE_SEND_SUCCESS,
} from '../constants/chatConstants';

export const chatListReducer = (state = {}, action) => {
    switch (action.type) {
        case CHAT_LIST_REQUEST:
            return { loading: true };
        case CHAT_LIST_SUCCESS:
            return {
                loading: false,
                chats: action.payload.chats,
                logs: action.payload.logs,
            };
        case MESSAGE_SEEN:
            return {
                loading: false,
                chats: action.payload.chats,
                logs: action.payload.logs,
            };
        case CHAT_LIST_FAIL:
            return { loading: false, error: action.payload };
        case CHAT_LIST_RESET:
            return {};
        default:
            return state;
    }
};

export const messageListReducer = (state = { messages: [] }, action) => {
    switch (action.type) {
        case MESSAGE_LIST_REQUEST:
            return { loading: true };
        case MESSAGE_LIST_SUCCESS:
            return {
                loading: false,
                messages: action.payload.messages,
                chatId: action.payload.chatId,
            };
        case MESSAGE_LIST_FAIL:
            return { loading: false, error: action.payload };
        case MESSAGE_LIST_RESET:
            return { messages: [] };
        default:
            return state;
    }
};

export const messageUploadReducer = (state = {}, action) => {
    switch (action.type) {
        case MESSAGE_SEND_REQUEST:
            return { loading: true };
        case MESSAGE_SEND_SUCCESS:
            return { loading: false, success: true };
        case MESSAGE_SEND_FAIL:
            return { loading: false, error: action.payload };
        case MESSAGE_SEND_RESET:
            return {};
        default:
            return state;
    }
};

export const createChatReducer = (state = {}, action) => {
    switch (action.type) {
        case CHAT_CREATE_REQUEST:
            return { loading: true };
        case CHAT_CREATE_SUCCESS:
            return { loading: false, success: true };
        case CHAT_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case CHAT_CREATE_RESET:
            return {};
        default:
            return state;
    }
};
