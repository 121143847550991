import { Avatar, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Logo({ noBg = false, size = 32, marginRight = 2 }) {
    return (
        <Link to="/">
            <Tooltip title="Home" arrow>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: marginRight }}
                >
                    {noBg ? (
                        <img
                            src={'/logo192.png'}
                            alt="Home"
                            width={size}
                            height={size}
                        />
                    ) : (
                        <Avatar
                            aria-label="go-home"
                            sx={{
                                bgcolor: 'white',
                            }}
                        >
                            <img
                                src={'/logo192.png'}
                                alt="Home"
                                width={32}
                                height={32}
                            />
                        </Avatar>
                    )}
                </IconButton>
            </Tooltip>
        </Link>
    );
}
