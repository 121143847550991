export const PREPARE_IMAGE_REQUEST = 'PREPARE_IMAGE_REQUEST';
export const PREPARE_IMAGE_SUCCESS = 'PREPARE_IMAGE_SUCCESS';
export const PREPARE_IMAGE_FAIL = 'PREPARE_IMAGE_FAIL';
export const PREPARE_IMAGE_RESET = 'PREPARE_IMAGE_RESET';

export const PREDICT_REQUEST = 'PREDICT_REQUEST';
export const PREDICT_SUCCESS = 'PREDICT_SUCCESS';
export const PREDICT_FAIL = 'PREDICT_FAIL';
export const PREDICT_RESET = 'PREDICT_RESET';

export const MODEL_IMAGE_UPLOAD_REQUEST = 'MODEL_IMAGE_UPLOAD_REQUEST';
export const MODEL_IMAGE_UPLOAD_SUCCESS = 'MODEL_IMAGE_UPLOAD_SUCCESS';
export const MODEL_IMAGE_UPLOAD_FAIL = 'MODEL_IMAGE_UPLOAD_FAIL';
export const MODEL_IMAGE_UPLOAD_RESET = 'MODEL_IMAGE_UPLOAD_RESET';

export const MODEL_REVIEW_REQUEST = 'MODEL_REVIEW_REQUEST';
export const MODEL_REVIEW_SUCCESS = 'MODEL_REVIEW_SUCCESS';
export const MODEL_REVIEW_FAIL = 'MODEL_REVIEW_FAIL';
export const MODEL_REVIEW_RESET = 'MODEL_REVIEW_RESET';
