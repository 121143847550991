export const USER_VALIDATE_REQUEST = 'USER_VALIDATE_REQUEST';
export const USER_VALIDATE_SUCCESS = 'USER_VALIDATE_SUCCESS';
export const USER_VALIDATE_FAIL = 'USER_VALIDATE_FAIL';
export const USER_VALIDATE_RESET = 'USER_VALIDATE_RESET';

export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';

export const USER_SIGNOUT_REQUEST = 'USER_SIGNOUT_REQUEST';
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS';
export const USER_SIGNOUT_FAIL = 'USER_SIGNOUT_FAIL';
export const USER_SIGNOUT_RESET = 'USER_SIGNOUT_RESET';

export const USER_ADDRESS_MAP_CONFIRM = 'USER_ADDRESS_MAP_CONFIRM';
export const USER_ADDRESS_MAP_RESET = 'USER_ADDRESS_MAP_RESET';

export const USER_MANUAL_PROFILE_CONFIRM = 'USER_MANUAL_PROFILE_CONFIRM';
export const USER_MANUAL_PROFILE_RESET = 'USER_MANUAL_PROFILE_RESET';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL';
export const USER_PROFILE_RESET = 'USER_PROFILE_RESET';

export const OTHER_USER_PROFILE_REQUEST = 'OTHER_USER_PROFILE_REQUEST';
export const OTHER_USER_PROFILE_SUCCESS = 'OTHER_USER_PROFILE_SUCCESS';
export const OTHER_USER_PROFILE_FAIL = 'OTHER_USER_PROFILE_FAIL';
export const OTHER_USER_PROFILE_RESET = 'OTHER_USER_PROFILE_RESET';

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';
export const UPDATE_USER_PROFILE_RESET = 'UPDATE_USER_PROFILE_RESET';

export const USER_LOCATION_ADDRESS_REQUEST = 'USER_LOCATION_ADDRESS_REQUEST';
export const USER_LOCATION_ADDRESS_SUCCESS = 'USER_LOCATION_ADDRESS_SUCCESS';
export const USER_LOCATION_ADDRESS_FAIL = 'USER_LOCATION_ADDRESS_FAIL';
export const USER_LOCATION_ADDRESS_RESET = 'USER_LOCATION_ADDRESS_RESET';

export const OTHER_USER_LOCATION_ADDRESS_REQUEST =
    'OTHER_USER_LOCATION_ADDRESS_REQUEST';
export const OTHER_USER_LOCATION_ADDRESS_SUCCESS =
    'OTHER_USER_LOCATION_ADDRESS_SUCCESS';
export const OTHER_USER_LOCATION_ADDRESS_FAIL =
    'OTHER_USER_LOCATION_ADDRESS_FAIL';
export const OTHER_USER_LOCATION_ADDRESS_RESET =
    'OTHER_USER_LOCATION_ADDRESS_RESET';

export const USER_REQUEST_REQUEST = 'USER_REQUEST_REQUEST';
export const USER_REQUEST_SUCCESS = 'USER_REQUEST_SUCCESS';
export const USER_REQUEST_FAIL = 'USER_REQUEST_FAIL';

export const USER_MEETINGS_REQUEST = 'USER_MEETINGS_REQUEST';
export const USER_MEETINGS_SUCCESS = 'USER_MEETINGS_SUCCESS';
export const USER_MEETINGS_FAIL = 'USER_MEETINGS_FAIL';
