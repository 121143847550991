import React, { useEffect, useState } from 'react';
import { IconButton, Badge, Menu } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsButtons from './NotificationsButtons';
import NotificationList from './NotificationList';
import { useDispatch, useSelector } from 'react-redux';
import {
    createUserToken,
    listNotifications,
} from '../../firebase/actions/notificationActions';
import isSubscribed from '../../onesignal/isSubscribed';
import OneSignal from 'react-onesignal';
import InstallApp from './InstallApp';
import { NOTIFICATION_LIST_FAIL } from '../../firebase/constants/notificationsConstants';

import { db } from '../../firebase';

import { ref, onValue } from 'firebase/database';
import { getUserNotificationsPath } from '../../functions/getPaths';

const NotificationMenu = ({ currentUser, userType }) => {
    const dispatch = useDispatch();
    const [permission, setPermission] = useState('default');
    const [subscription, setSubscription] = useState(false);
    const [badgeNumber, setBadgeNumber] = useState(0);

    const notificationList = useSelector((state) => state.notificationList);

    const { notifications } = notificationList;

    const menuId = 'notifications-menu';

    const [anchorEl, setAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // useEffect(() => {
    //     if (currentUser) {
    //         dispatch(listNotificationsCallback(currentUser));
    //     }
    // }, [dispatch, currentUser]);

    useEffect(() => {
        if (currentUser) {
            const notificationRef = ref(
                db,
                getUserNotificationsPath(currentUser.uid)
            );

            const callback = async (snapshot) => {
                const notifications = snapshot.val();
                if (notifications) {
                    dispatch(listNotifications(notifications));
                }
            };

            const errorHandler = (error) => {
                dispatch({
                    type: NOTIFICATION_LIST_FAIL,
                    payload: error.message,
                });
            };

            return onValue(notificationRef, callback, errorHandler);
        }
    }, [dispatch, currentUser]);

    useEffect(() => {
        if (notifications) {
            const count = notifications.filter(
                (obj) => obj.data.read === 'false'
            ).length;
            setBadgeNumber(count);
        }
    }, [notifications]);

    useEffect(() => {
        const handlePermissionChange = (permissionChange) => {
            // Handle the received notification here
            console.log('PERMISSION CHANGE: ' + permissionChange);
            if (permissionChange?.to) {
                console.log('PERMISSION CHANGE GRANTED');
                setPermission(permissionChange.to);
            }
        };

        OneSignal.on('notificationPermissionChange', handlePermissionChange);

        // Clean up the event listener when the component is unmounted
        return () => {
            OneSignal.off(
                'notificationPermissionChange',
                handlePermissionChange
            );
        };
    }, [dispatch, currentUser, userType]);

    useEffect(() => {
        const handleSubscriptionChange = (subscription) => {
            // Handle the received notification here
            console.log('SUBSCRIPTION CHANGE: ' + subscription);
            if (subscription) {
                setSubscription(subscription);
                OneSignal.getUserId().then((id) => {
                    if (id && currentUser && userType) {
                        if (permission === 'granted') {
                            dispatch(
                                createUserToken(id, currentUser, userType)
                            );
                            setPermission('reset');
                        } else {
                            dispatch(
                                createUserToken(id, currentUser, userType, true)
                            );
                        }
                    }
                });
            }
        };

        OneSignal.on('subscriptionChange', handleSubscriptionChange);

        // Clean up the event listener when the component is unmounted
        return () => {
            OneSignal.off('subscriptionChange', handleSubscriptionChange);
        };
    }, [dispatch, currentUser, userType, permission]);

    useEffect(() => {
        const request = async () => {
            const thisPermission = await isSubscribed();
            setSubscription(thisPermission);
        };
        request();
    }, []);

    return (
        <>
            {subscription ? (
                <IconButton
                    size="large"
                    aria-label="Notifications"
                    color="inherit"
                    onClick={handleProfileMenuOpen}
                >
                    <Badge badgeContent={badgeNumber} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
            ) : (
                <InstallApp />
            )}
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                id={menuId}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <NotificationsButtons handleMenuClose={handleMenuClose} />
                <NotificationList
                    user={currentUser}
                    handleMenuClose={handleMenuClose}
                />
            </Menu>
        </>
    );
};

export default NotificationMenu;
