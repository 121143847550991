export const GROWER_GENERAL_PRODUCT = 'grower-general-product';
export const GROWER_MESSAGE_OFFER = 'grower-message-offer';
export const TRADER_GENERAL_PROCUREMENT = 'buyer-general-procurement';
export const STANDING_ORDER_SETUP = 'standing-order-setup';
export const MORE_PHOTOS = 'more-photos';

function getLink(product) {
    return `https://market.kuronga.com/product/${
        product._id ? product._id : product.productId
    }/vtype/${product.farmerId ? 'product' : 'purchase'}`;
}

export function getMessageContent(
    product,
    messageContent = GROWER_GENERAL_PRODUCT
) {
    let message = '';
    switch (messageContent) {
        case GROWER_GENERAL_PRODUCT: {
            console.log('GROWER_GENERAL_PRODUCT');
            const linkPath = getLink(product);
            message = `Hi,\nI have a question about your post for ${product.crop.cropName}\n${linkPath}\n\n`;
            break;
        }
        case GROWER_MESSAGE_OFFER: {
            console.log('GROWER_MESSAGE_OFFER');
            const linkPath = getLink(product);
            message = `Hi,\nI would like to make an offer for your ${product.crop.cropName}\n${linkPath}\n\n`;
            break;
        }
        case TRADER_GENERAL_PROCUREMENT: {
            const linkPath = getLink(product);
            message = `Hi,\nI have a question about your procurement post for ${product.crop.cropName}\n${linkPath}\n\n`;
            break;
        }
        case STANDING_ORDER_SETUP: {
            const linkPath = getLink(product);
            message = `Hi,\nI want to set a Standing order for your listing of ${product.crop.cropName}.\n${linkPath}\n\nHowever, I would like to make the following amendments: \n\n\nPlease could you update the existing listing to reflect this so that I can set up the standing order.`;
            break;
        }
        case MORE_PHOTOS: {
            const linkPath = getLink(product);
            message = `Hi,\nI noticed that your ${product.crop.cropName} listing could do a few more photos.\nThis is the listing here: ${linkPath}\n\nPlease consider adding more photos I would like to see some more visual details.`;
            break;
        }
        default: {
            message = 'Hi, I have a question\n\n';
        }
    }
    return message;
}
