import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
const db = getDatabase();
const auth = getAuth();
const st = getStorage();
const func = getFunctions(getApp(), 'europe-west2');

if (window.location.hostname === 'localhost') {
    // Point to the RTDB emulator running on localhost.
    connectDatabaseEmulator(db, 'localhost', 9000);

    connectFunctionsEmulator(func, 'localhost', 5001);
    connectAuthEmulator(auth, 'http://localhost:9099');

    connectStorageEmulator(st, 'localhost', 9199);
}

export { db, st, auth, func };

export default app;
