export const farmers = [
    {
        tag: 'farmers',
        label: 'Account Updates',
    },
    {
        tag: 'news',
        label: 'News',
    },
];

export const buyers = [
    {
        tag: 'buyers',
        label: 'Account Updates',
    },
    {
        tag: 'news',
        label: 'News',
    },
];
