import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import RefreshIcon from '@mui/icons-material/Refresh';
import { getServerVersion } from '../../firebase/actions/notificationActions';

export default function Update() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [localVersion, setLocalVersion] = useState(
        process.env.REACT_APP_VERSION
    );

    const currentVersion = useSelector((state) => state.currentVersion);

    const { version, loading, error } = currentVersion;

    const handleReload = () => {
        window.location.reload(true);
    };

    useEffect(() => {
        dispatch(getServerVersion());
    }, []);

    useEffect(() => {
        if (localVersion && version && !loading && !error) {
            if (localVersion !== version) {
                setOpen(true);
            }
        }
    }, [localVersion, version, loading, error]);

    return (
        <Dialog open={open} onClose={handleReload}>
            <DialogTitle id="dialog-title">Update Required!</DialogTitle>
            <DialogContent>
                Tap reload to get the latest version of the app.
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={handleReload}
                    fullWidth
                    endIcon={<RefreshIcon />}
                    sx={{ m: 2 }}
                >
                    Reload
                </Button>
            </DialogActions>
        </Dialog>
    );
}
