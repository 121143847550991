import React, { useEffect, useState } from 'react';
import { Chip, DialogContent, Divider, Stack, Typography } from '@mui/material';
import ForwardClickButton from '../Buttons/ForwardClickButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useSelector } from 'react-redux';

export default function UserProgress({ setOpen }) {
    let history = useHistory();
    const { currentUser, userType } = useAuth();

    const setupConfig = useSelector((state) => state.setupConfig);
    const { setup } = setupConfig;

    const [setupState, setSetupState] = useState(false);

    useEffect(() => {
        if (setup) {
            setSetupState(setup);
        }
    }, [setup]);

    return (
        <DialogContent spacing={2}>
            <Divider sx={{ m: 1 }} />
            <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
            >
                <AccountCircleIcon
                    color={
                        setupState && setupState.profileComplete
                            ? 'primary'
                            : 'secondary'
                    }
                />
                <Stack>
                    <Typography variant="subtitle2">
                        Set up Your Profile
                    </Typography>
                    {setupState && setupState.profileComplete ? (
                        <Chip color="primary" label="Complete" sx={{ m: 1 }} />
                    ) : (
                        <Chip label="Incomplete" sx={{ m: 1 }} />
                    )}
                </Stack>
                <ForwardClickButton
                    handleClick={() => {
                        history.push(`/my-profile`);
                        setOpen(false);
                    }}
                    toolTip="My Profile"
                />
            </Stack>

            <Divider sx={{ m: 1 }} />

            <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
            >
                <HomeIcon
                    color={
                        setupState && setupState.addressComplete
                            ? 'primary'
                            : 'secondary'
                    }
                />
                <Stack>
                    <Typography variant="subtitle2">
                        Set up your{' '}
                        {userType.isFarmer
                            ? 'Dispatch'
                            : userType.isTrader
                            ? 'Delivery'
                            : ''}{' '}
                        Address
                    </Typography>
                    {setupState && setupState.addressComplete ? (
                        <Chip color="primary" label="Complete" sx={{ m: 1 }} />
                    ) : (
                        <Chip label="Incomplete" sx={{ m: 1 }} />
                    )}
                </Stack>
                <ForwardClickButton
                    handleClick={() => {
                        history.push(`/my-profile`);
                        setOpen(false);
                    }}
                    toolTip="My Address"
                />
            </Stack>

            <Divider sx={{ m: 1 }} />

            {userType.isFarmer && (
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <MonetizationOnIcon
                        color={
                            setupState && setupState.listingComplete
                                ? 'primary'
                                : 'secondary'
                        }
                    />
                    <Stack>
                        <Typography variant="subtitle2">
                            List your produce to sell
                        </Typography>
                        {setupState && setupState.listingComplete ? (
                            <Chip
                                color="primary"
                                label="Complete"
                                sx={{ m: 1 }}
                            />
                        ) : (
                            <Chip label="Incomplete" sx={{ m: 1 }} />
                        )}
                    </Stack>
                    <ForwardClickButton
                        handleClick={() => {
                            history.push(`/sales/${currentUser.uid}`);
                            setOpen(false);
                        }}
                        toolTip="My Listings"
                    />
                </Stack>
            )}

            {userType.isTrader && (
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <MonetizationOnIcon
                        color={
                            setupState && setupState.listingComplete
                                ? 'primary'
                                : 'secondary'
                        }
                    />
                    <Stack>
                        <Typography variant="subtitle2">
                            List your procurement needs
                        </Typography>
                        {setupState && setupState.listingComplete ? (
                            <Chip
                                color="primary"
                                label="Complete"
                                sx={{ m: 1 }}
                            />
                        ) : (
                            <Chip label="Incomplete" sx={{ m: 1 }} />
                        )}
                    </Stack>
                    <ForwardClickButton
                        handleClick={() => {
                            history.push(`/purchasing/${currentUser.uid}`);
                            setOpen(false);
                        }}
                        toolTip="My Listings"
                    />
                </Stack>
            )}
            <Divider sx={{ m: 1 }} />

            {userType.isFarmer && (
                <Typography variant="caption">
                    Once you have listed your produce sit tight an wait for the
                    purchase orders to arrive in your inbox.
                </Typography>
            )}

            {userType.isTrader && (
                <Typography variant="caption">
                    Once you have filled out your profile you are ready to start
                    placing orders on the platform.
                </Typography>
            )}
        </DialogContent>
    );
}
