export function getUserTypeNumber(userType) {
    if (userType.isFarmer) {
        return 1;
    }
    if (userType.isDriver) {
        return 2;
    }
    if (userType.isTrader) {
        return 3;
    } else {
        return 0;
    }
}

export function getOtherChatUser(users, uid) {
    let otherUser = '';
    for (const userId of Object.keys(users)) {
        if (userId !== uid) {
            otherUser = users[userId];
            otherUser._id = userId;
            otherUser.initials = getUserName(otherUser)
                .split(' ')
                .map((n) => n[0])
                .join('');
        }
    }
    return otherUser;
}

export function getUserLcString(uid, userType) {
    return `${
        userType.isFarmer
            ? 'farmer'
            : userType.isDriver
            ? 'driver'
            : userType.isTrader
            ? 'trader'
            : null
    }/${uid}`;
}

export function getUserTypeName(userType) {
    var user = 'User';
    if (typeof userType === 'number') {
        switch (userType) {
            case 1:
                user = 'Farmer';
                break;
            case 2:
                user = 'Driver';
                break;
            case 3:
                user = 'Trader';
                break;
            default:
                break;
        }
    } else if (typeof userType === 'string') {
        switch (userType) {
            case '1':
                user = 'Farmer';
                break;
            case '2':
                user = 'Driver';
                break;
            case '3':
                user = 'Trader';
                break;
            default:
                break;
        }
    } else {
        user = userType.isFarmer
            ? 'Farmer'
            : userType.isDriver
            ? 'Driver'
            : userType.isTrader
            ? 'Buyer'
            : 'User';
    }
    return user;
}

export function getUserTypeFromInt(userType) {
    var userObject = { isFarmer: false, isDriver: false, isTrader: false };
    if (userType === 1) {
        userObject.isFarmer = true;
        return userObject;
    }
    if (userType === 2) {
        userObject.isDriver = true;
        return userObject;
    }
    if (userType === 3) {
        userObject.isTrader = true;
        return userObject;
    } else {
        return userObject;
    }
}

export function getUserName(UserObject) {
    var userName = 'User';
    if (UserObject.name) {
        userName = UserObject.name;
    } else if (UserObject.firstName && UserObject.lastName) {
        userName = `${UserObject.firstName} ${UserObject.lastName}`;
    }
    return userName;
}

export function getUserType(userTypeInt) {
    var userType = { isFarmer: false, isDriver: false, isTrader: false };
    switch (userTypeInt) {
        case 1:
            userType.isFarmer = true;
            break;
        case 2:
            userType.isDriver = true;
            break;
        case 3:
            userType.isTrader = true;
            break;
        default:
            break;
    }
    return userType;
}
