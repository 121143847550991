import { CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    loading: {
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1),
        flexDirection: 'row',
        margin: theme.spacing(2),
    },
}));

export default function LoadingCompact({ loading }) {
    const classes = useStyles();
    return (
        <Stack className={classes.loading} direction="column">
            <CircularProgress size="2.5rem" sx={{ m: 2 }} />
            <Typography variant="h3" className={classes.message} sx={{ m: 2 }}>
                {loading}
            </Typography>
        </Stack>
    );
}
