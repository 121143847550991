import {
    GET_TRAINING_DATA_FAIL,
    GET_TRAINING_DATA_REQUEST,
    GET_TRAINING_DATA_SUCCESS,
    SAVE_SWIPE_LABELS_FAIL,
    SAVE_SWIPE_LABELS_REQUEST,
    SAVE_SWIPE_LABELS_RESET,
    SAVE_SWIPE_LABELS_SUCCESS,
    TRAINING_IMAGE_UPLOAD_FAIL,
    TRAINING_IMAGE_UPLOAD_REQUEST,
    TRAINING_IMAGE_UPLOAD_RESET,
    TRAINING_IMAGE_UPLOAD_SUCCESS,
    UPDATE_TRAINING_DATA_FAIL,
    UPDATE_TRAINING_DATA_REQUEST,
    UPDATE_TRAINING_DATA_RESET,
    UPDATE_TRAINING_DATA_SUCCESS,
} from '../constants/trainingConstants';

export const trainingImageUploadReducer = (
    state = { productImages: [] },
    action
) => {
    switch (action.type) {
        case TRAINING_IMAGE_UPLOAD_REQUEST:
            return { loading: true };
        case TRAINING_IMAGE_UPLOAD_SUCCESS:
            return { loading: false, success: true };
        case TRAINING_IMAGE_UPLOAD_FAIL:
            return { loading: false, error: action.payload };
        case TRAINING_IMAGE_UPLOAD_RESET:
            return {};
        default:
            return state;
    }
};

export const trainingDataListReducer = (
    state = { trainingData: [] },
    action
) => {
    switch (action.type) {
        case GET_TRAINING_DATA_REQUEST:
            return { loading: true };
        case GET_TRAINING_DATA_SUCCESS:
            return { loading: false, trainingData: action.payload };
        case GET_TRAINING_DATA_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const updateTrainingDataReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_TRAINING_DATA_REQUEST:
            return { loading: true };
        case UPDATE_TRAINING_DATA_SUCCESS:
            return { loading: false, success: true };
        case UPDATE_TRAINING_DATA_FAIL:
            return { loading: false, error: action.payload };
        case UPDATE_TRAINING_DATA_RESET:
            return {};
        default:
            return state;
    }
};

export const saveSwipesReducer = (state = {}, action) => {
    switch (action.type) {
        case SAVE_SWIPE_LABELS_REQUEST:
            return { loading: true };
        case SAVE_SWIPE_LABELS_SUCCESS:
            return { loading: false, success: true };
        case SAVE_SWIPE_LABELS_FAIL:
            return { loading: false, error: action.payload };
        case SAVE_SWIPE_LABELS_RESET:
            return {};
        default:
            return state;
    }
};
