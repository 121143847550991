import React from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import showSubscriptions from '../../onesignal/showSubscriptions';

export default function NotificationsButtons({ handleMenuClose }) {
    const open = () => {
        showSubscriptions();
    };
    return (
        <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item>
                <Tooltip title="Close" arrow>
                    <IconButton onClick={handleMenuClose}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title="Settings" arrow>
                    <IconButton onClick={() => open()}>
                        <SettingsIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
}
