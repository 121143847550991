import {
    Avatar,
    CardMedia,
    Divider,
    Grid,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getNotificationDate } from '../../functions/dateFromatting';
import MessageBox from '../MessageBox/MessageBox';
import { updateNotification } from '../../firebase/actions/notificationActions';
import { useHistory } from 'react-router-dom';

import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import SmallLoading from '../LoadingBox/SmallLoading';

const NotificationList = ({ user, handleMenuClose }) => {
    let history = useHistory();
    const dispatch = useDispatch();

    const notificationList = useSelector((state) => state.notificationList);

    const { notifications, loading, error } = notificationList;

    // const notificationsUpdate = useSelector(
    //     (state) => state.notificationsUpdate
    // );

    // const {
    //     success,
    //     loading: loadingUpdate,
    //     error: errorUpdate,
    // } = notificationsUpdate;

    const handleClick = (notification) => {
        dispatch(
            updateNotification(notification.data.notificationId, user, 'read')
        );
        if (notification?.data?.link) {
            const link = notification.data.link;
            const localLink = link.replace('https://market.kuronga.com', '');
            history.push(localLink);
            handleMenuClose();
        }
    };

    // useEffect(() => {
    //     if (success && !loadingUpdate && !errorUpdate && user) {
    //         console.log('success update Notifications triggered');
    //         dispatch(listNotifications(user));
    //     }
    // }, [dispatch, success, loadingUpdate, errorUpdate, user]);

    return (
        <>
            <Typography variant="h5" gutterBottom sx={{ ml: 2 }}>
                {' '}
                Notifications
            </Typography>
            {loading ? (
                <>
                    <SmallLoading loading="Loading" />
                    <MessageBox>
                        <Typography variant="h6">
                            No Notifications Yet
                        </Typography>
                        <Typography>
                            Your notifications will appear here once you have
                            received them.
                        </Typography>
                    </MessageBox>
                </>
            ) : error ? (
                <MessageBox variant="danger">{error}</MessageBox>
            ) : notifications?.length === 0 ? (
                <MessageBox>
                    <Typography variant="h6">No Notifications Yet</Typography>
                    <Typography>
                        Your notifications will appear here once you have
                        received them.
                    </Typography>
                </MessageBox>
            ) : (
                <>
                    {notifications?.map((notification, index) => (
                        <MenuItem
                            onClick={() => handleClick(notification)}
                            key={index}
                        >
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                {notification?.chrome_web_icon && (
                                    <Grid item xs={2}>
                                        <Avatar
                                            sx={{
                                                backgroundColor: 'white',
                                                mr: 2,
                                            }}
                                        >
                                            {
                                                <CardMedia
                                                    component="img"
                                                    image={
                                                        notification.chrome_web_icon
                                                    }
                                                    title="notification-image"
                                                />
                                            }
                                        </Avatar>
                                    </Grid>
                                )}
                                <Grid item xs={8}>
                                    <Stack direction="column" width="100%">
                                        <Typography>
                                            {notification?.headings?.en
                                                ? notification.headings.en
                                                : ''}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            whiteSpace="pre-wrap"
                                        >
                                            {notification?.contents?.en
                                                ? notification.contents.en
                                                : ''}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="secondary"
                                        >
                                            {getNotificationDate(
                                                notification?.data?.timestamp
                                                    ? notification.data
                                                          .timestamp
                                                    : Date.now()
                                            )}
                                        </Typography>
                                        {index < notifications?.length - 1 && (
                                            <Divider sx={{ p: 1 }} />
                                        )}
                                    </Stack>
                                </Grid>
                                {notification?.data?.read &&
                                    notification.data.read === 'false' && (
                                        <Grid item xs={2}>
                                            <CircleNotificationsIcon color="primary" />
                                        </Grid>
                                    )}
                            </Grid>
                        </MenuItem>
                    ))}
                </>
            )}
        </>
    );
};

export default NotificationList;
