import {
    MODEL_REVIEW_FAIL,
    MODEL_REVIEW_REQUEST,
    MODEL_REVIEW_RESET,
    MODEL_REVIEW_SUCCESS,
    PREDICT_FAIL,
    PREDICT_REQUEST,
    PREDICT_RESET,
    PREDICT_SUCCESS,
    PREPARE_IMAGE_FAIL,
    PREPARE_IMAGE_REQUEST,
    PREPARE_IMAGE_RESET,
    PREPARE_IMAGE_SUCCESS,
} from '../constants/modelConstants';

export const preparedImageReducer = (state = {}, action) => {
    switch (action.type) {
        case PREPARE_IMAGE_REQUEST:
            return { loading: true };
        case PREPARE_IMAGE_SUCCESS:
            return { loading: false, success: true, imageData: action.payload };
        case PREPARE_IMAGE_FAIL:
            return { loading: false, error: action.payload };
        case PREPARE_IMAGE_RESET:
            return {};
        default:
            return state;
    }
};

export const predictionReducer = (state = {}, action) => {
    switch (action.type) {
        case PREDICT_REQUEST:
            return { loading: true };
        case PREDICT_SUCCESS:
            return { loading: false, success: true, imageData: action.payload };
        case PREDICT_FAIL:
            return { loading: false, error: action.payload };
        case PREDICT_RESET:
            return {};
        default:
            return state;
    }
};

export const modelReviewReducer = (state = {}, action) => {
    switch (action.type) {
        case MODEL_REVIEW_REQUEST:
            return { loading: true };
        case MODEL_REVIEW_SUCCESS:
            return {
                loading: false,
                success: true,
                reviewUpdate: action.payload,
            };
        case MODEL_REVIEW_FAIL:
            return { loading: false, error: action.payload };
        case MODEL_REVIEW_RESET:
            return {};
        default:
            return state;
    }
};
