import {
    GET_MEETINGS_FAIL,
    GET_MEETINGS_REQUEST,
    GET_MEETINGS_RESET,
    GET_MEETINGS_SUCCESS,
} from '../constants/meetingsConstants';

export const meetingsListReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_MEETINGS_REQUEST:
            return { loading: true };
        case GET_MEETINGS_SUCCESS:
            return { loading: false, meetings: action.payload };
        case GET_MEETINGS_FAIL:
            return { loading: false, error: action.payload };
        case GET_MEETINGS_RESET:
            return {};
        default:
            return state;
    }
};
