import {
    ADD_SENDER_LIST_FAIL,
    ADD_SENDER_LIST_REQUEST,
    ADD_SENDER_LIST_RESET,
    ADD_SENDER_LIST_SUCCESS,
    SENDER_LIST_FAIL,
    SENDER_LIST_REQUEST,
    SENDER_LIST_RESET,
    SENDER_LIST_SUCCESS,
} from '../constants/safeSenderConstants';

export const addSenderReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_SENDER_LIST_REQUEST:
            return { loading: true };
        case ADD_SENDER_LIST_SUCCESS:
            return { loading: false, success: true };
        case ADD_SENDER_LIST_FAIL:
            return { loading: false, error: action.payload };
        case ADD_SENDER_LIST_RESET:
            return {};
        default:
            return state;
    }
};

export const safeSendersReducer = (state = {}, action) => {
    switch (action.type) {
        case SENDER_LIST_REQUEST:
            return { loading: true };
        case SENDER_LIST_SUCCESS:
            return { loading: false, senders: action.payload };
        case SENDER_LIST_FAIL:
            return { loading: false, error: action.payload };
        case SENDER_LIST_RESET:
            return {};
        default:
            return state;
    }
};
