import { makeStyles } from '@mui/styles';
// import theme from '../appTheme';

const useStyles = makeStyles((theme) => ({
    backStack: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        alignItems: 'center',
    },
    whatsApp: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    save: {
        position: 'fixed',
        bottom: theme.spacing(8),
        right: theme.spacing(4),
    },
}));

export default useStyles;
