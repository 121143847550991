import React from 'react';
import {
    Avatar,
    Badge,
    IconButton,
    MenuItem,
    Paper,
    Stack,
    Typography,
} from '@mui/material';

import AccountCircle from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useSelector } from 'react-redux';
import { useAuth } from '../../contexts/AuthContext';

export default function ProfileMenuItem({ props, handleMenuClose }) {
    const cart = useSelector((state) => state.cart);
    const { currentUser, userType } = useAuth();
    const { cartItems } = cart;
    const badgeNumber = 0;
    return (
        <Paper sx={{ p: 1 }}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <MenuItem
                    onClick={() => {
                        props.history.push('/my-profile');
                        handleMenuClose();
                    }}
                    sx={{ width: '100%' }}
                >
                    <Avatar
                        alt="your profile"
                        sx={{ m: 1, bgcolor: 'secondary.main' }}
                        src={
                            currentUser && currentUser.photoURL
                                ? currentUser.photoURL
                                : null
                        }
                    >
                        <AccountCircle sx={{ width: 32, height: 32 }} />
                    </Avatar>

                    <Typography variant="inherit" noWrap>
                        {currentUser && currentUser.displayName
                            ? currentUser.displayName
                            : 'Profile'}
                    </Typography>
                </MenuItem>
                <IconButton
                    size="large"
                    aria-label="shopping cart"
                    color="inherit"
                    onClick={() => {
                        props.history.push('/cart');
                        handleMenuClose();
                    }}
                >
                    {userType && userType.isTrader && (
                        <Badge badgeContent={cartItems.length} color="error">
                            <ShoppingCartIcon />
                        </Badge>
                    )}
                </IconButton>
                <IconButton
                    size="large"
                    aria-label="Notifications"
                    color="inherit"
                    onClick={() => {
                        props.history.push('/message-centre');
                        handleMenuClose();
                    }}
                >
                    <Badge badgeContent={badgeNumber} color="error">
                        <MailOutlineIcon />
                    </Badge>
                </IconButton>
            </Stack>
        </Paper>
    );
}
