export const USER_IMAGE_UPLOAD_REQUEST = 'USER_IMAGE_UPLOAD_REQUEST';
export const USER_IMAGE_UPLOAD_SUCCESS = 'USER_IMAGE_UPLOAD_SUCCESS';
export const USER_IMAGE_UPLOAD_FAIL = 'USER_IMAGE_UPLOAD_FAIL';
export const USER_IMAGE_UPLOAD_RESET = 'USER_IMAGE_UPLOAD_RESET';

export const USER_PROFILE_IMAGE_REQUEST = 'USER_PROFILE_IMAGE_REQUEST';
export const USER_PROFILE_IMAGE_SUCCESS = 'USER_PROFILE_IMAGE_SUCCESS';
export const USER_PROFILE_IMAGE_FAIL = 'USER_PROFILE_IMAGE_FAIL';
export const USER_PROFILE_IMAGE_RESET = 'USER_PROFILE_IMAGE_RESET';

export const LOGO_UPLOAD_REQUEST = 'LOGO_UPLOAD_REQUEST';
export const LOGO_UPLOAD_SUCCESS = 'LOGO_UPLOAD_SUCCESS';
export const LOGO_UPLOAD_FAIL = 'LOGO_UPLOAD_FAIL';
export const LOGO_UPLOAD_RESET = 'LOGO_UPLOAD_RESET';

export const LOGO_IMAGE_REQUEST = 'LOGO_IMAGE_REQUEST';
export const LOGO_IMAGE_SUCCESS = 'LOGO_IMAGE_SUCCESS';
export const LOGO_IMAGE_FAIL = 'LOGO_IMAGE_FAIL';
export const LOGO_IMAGE_RESET = 'LOGO_IMAGE_RESET';

export const BUSINESS_INFORMATION_REQUEST = 'BUSINESS_INFORMATION_REQUEST';
export const BUSINESS_INFORMATION_SUCCESS = 'BUSINESS_INFORMATION_SUCCESS';
export const BUSINESS_INFORMATION_FAIL = 'BUSINESS_INFORMATION_FAIL';
export const BUSINESS_INFORMATION_RESET = 'BUSINESS_INFORMATION_RESET';
