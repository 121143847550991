import {
    SIGNUP_LOCATION_FAIL,
    SIGNUP_LOCATION_REQUEST,
    SIGNUP_LOCATION_SUCCESS,
} from '../constants/locationConstants';

export const signupLocationListReducer = (
    state = { signupLocations: [] },
    action
) => {
    switch (action.type) {
        case SIGNUP_LOCATION_REQUEST:
            return { loading: true };
        case SIGNUP_LOCATION_SUCCESS:
            return { loading: false, signupLocations: action.payload };
        case SIGNUP_LOCATION_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
