export const NOTIFICATION_CREATE_REQUEST = 'NOTIFICATION_CREATE_REQUEST';
export const NOTIFICATION_CREATE_SUCCESS = 'NOTIFICATION_CREATE_SUCCESS';
export const NOTIFICATION_CREATE_FAIL = 'NOTIFICATION_CREATE_FAIL';
export const NOTIFICATION_CREATE_RESET = 'NOTIFICATION_CREATE_RESET';

export const NOTIFICATION_LIST_REQUEST = 'NOTIFICATION_LIST_REQUEST';
export const NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST_SUCCESS';
export const NOTIFICATION_LIST_FAIL = 'NOTIFICATION_LIST_FAIL';
export const NOTIFICATION_LIST_RESET = 'NOTIFICATION_LIST_RESET';

export const ADD_BACKGROUND_NOTIFICATIONS = 'ADD_BACKGROUND_NOTIFICATIONS';

export const NOTIFICATIONS_UPDATE_REQUEST = 'NOTIFICATIONS_UPDATE_REQUEST';
export const NOTIFICATIONS_UPDATE_SUCCESS = 'NOTIFICATIONS_UPDATE_SUCCESS';
export const NOTIFICATIONS_UPDATE_FAIL = 'NOTIFICATIONS_UPDATE_FAIL';
export const NOTIFICATIONS_UPDATE_RESET = 'NOTIFICATIONS_UPDATE_RESET';

export const GET_VERSION_REQUEST = 'GET_VERSION_REQUEST';
export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS';
export const GET_VERSION_FAIL = 'GET_VERSION_FAIL';
export const GET_VERSION_RESET = 'GET_VERSION_RESET';
