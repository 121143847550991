import {
    ADD_MEETINGS_FAIL,
    ADD_MEETINGS_REQUEST,
    ADD_MEETINGS_SUCCESS,
    AUTHORISE_NEW_USER_FAIL,
    AUTHORISE_NEW_USER_REQUEST,
    AUTHORISE_NEW_USER_RESET,
    AUTHORISE_NEW_USER_SAVE,
    AUTHORISE_NEW_USER_SUCCESS,
    CHECK_IF_USER_EXISTS_FAIL,
    CHECK_IF_USER_EXISTS_REQUEST,
    CHECK_IF_USER_EXISTS_RESET,
    CHECK_IF_USER_EXISTS_SUCCESS,
    DELETE_MEETING_FAIL,
    DELETE_MEETING_REQUEST,
    DELETE_MEETING_RESET,
    DELETE_MEETING_SUCCESS,
    GET_AUTHORISED_USERS_FAIL,
    GET_AUTHORISED_USERS_REQUEST,
    GET_AUTHORISED_USERS_RESET,
    GET_AUTHORISED_USERS_SUCCESS,
    GET_CLAIMS_FAIL,
    GET_CLAIMS_REQUEST,
    GET_CLAIMS_RESET,
    GET_CLAIMS_SUCCESS,
    GET_CURRENT_SALES_POSTS_FAIL,
    GET_CURRENT_SALES_POSTS_REQUEST,
    GET_CURRENT_SALES_POSTS_RESET,
    GET_CURRENT_SALES_POSTS_SUCCESS,
    GET_CURRENT_USER_LOCATION_FAIL,
    GET_CURRENT_USER_LOCATION_REQUEST,
    GET_CURRENT_USER_LOCATION_RESET,
    GET_CURRENT_USER_LOCATION_SUCCESS,
    GET_NEW_USERS_FAIL,
    GET_NEW_USERS_REQUEST,
    GET_NEW_USERS_RESET,
    GET_NEW_USERS_SUCCESS,
    GET_REGISTERED_USERS_CLEAR,
    GET_REGISTERED_USERS_FAIL,
    GET_REGISTERED_USERS_FILTER,
    GET_REGISTERED_USERS_REQUEST,
    GET_REGISTERED_USERS_RESET,
    GET_REGISTERED_USERS_SUCCESS,
    SEND_EMAIL_FAIL,
    SEND_EMAIL_REQUEST,
    SEND_EMAIL_RESET,
    SEND_EMAIL_SUCCESS,
    SIGNIN_ADDRESS_FAIL,
    SIGNIN_ADDRESS_REQUEST,
    SIGNIN_ADDRESS_RESET,
    SIGNIN_ADDRESS_SUCCESS,
    UPDATE_CLAIMS_FAIL,
    UPDATE_CLAIMS_REQUEST,
    UPDATE_CLAIMS_RESET,
    UPDATE_CLAIMS_SUCCESS,
    UPDATE_CROP_FAIL,
    UPDATE_CROP_REQUEST,
    UPDATE_CROP_RESET,
    UPDATE_CROP_SUCCESS,
    UPDATE_CURRENT_SALES_POSTS_FAIL,
    UPDATE_CURRENT_SALES_POSTS_REQUEST,
    UPDATE_CURRENT_SALES_POSTS_RESET,
    UPDATE_CURRENT_SALES_POSTS_SUCCESS,
    UPDATE_CURRENT_USER_LOCATION_FAIL,
    UPDATE_CURRENT_USER_LOCATION_REQUEST,
    UPDATE_CURRENT_USER_LOCATION_RESET,
    UPDATE_CURRENT_USER_LOCATION_SUCCESS,
    UPLOAD_CROP_TYPE_LIST_FAIL,
    UPLOAD_CROP_TYPE_LIST_REQUEST,
    UPLOAD_CROP_TYPE_LIST_RESET,
    UPLOAD_CROP_TYPE_LIST_SUCCESS,
} from '../constants/adminConstants';

export const uploadCroptypeListReducer = (state = {}, action) => {
    switch (action.type) {
        case UPLOAD_CROP_TYPE_LIST_REQUEST:
            return { loading: true };
        case UPLOAD_CROP_TYPE_LIST_SUCCESS:
            return { loading: false, success: true };
        case UPLOAD_CROP_TYPE_LIST_FAIL:
            return { loading: false, error: action.payload };
        case UPLOAD_CROP_TYPE_LIST_RESET:
            return {};
        default:
            return state;
    }
};

export const newUsersListReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_NEW_USERS_REQUEST:
            return { loading: true };
        case GET_NEW_USERS_SUCCESS:
            return { loading: false, newUsers: action.payload };
        case GET_NEW_USERS_FAIL:
            return { loading: false, error: action.payload };
        case GET_NEW_USERS_RESET:
            return {};
        default:
            return state;
    }
};

export const authoriseNewUserReducer = (state = {}, action) => {
    switch (action.type) {
        case AUTHORISE_NEW_USER_REQUEST:
            return { loading: true, authorisedUser: action.payload };
        case AUTHORISE_NEW_USER_SAVE:
            return { loading: false, authorisedUser: action.payload };
        case AUTHORISE_NEW_USER_SUCCESS:
            return {
                loading: false,
                success: true,
                authorisedUser: action.payload,
            };
        case AUTHORISE_NEW_USER_FAIL:
            return { loading: false, error: action.payload };
        case AUTHORISE_NEW_USER_RESET:
            return {};
        default:
            return state;
    }
};

export const updateSingleCropReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_CROP_REQUEST:
            return { loading: true };
        case UPDATE_CROP_SUCCESS:
            return { loading: false, success: true };
        case UPDATE_CROP_FAIL:
            return { loading: false, error: action.payload };
        case UPDATE_CROP_RESET:
            return {};
        default:
            return state;
    }
};

export const authorisedUserListReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_AUTHORISED_USERS_REQUEST:
            return { loading: true };
        case GET_AUTHORISED_USERS_SUCCESS:
            return { loading: false, authorisedUsers: action.payload };
        case GET_AUTHORISED_USERS_FAIL:
            return { loading: false, error: action.payload };
        case GET_AUTHORISED_USERS_RESET:
            return {};
        default:
            return state;
    }
};

export const registeredUserListReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_REGISTERED_USERS_REQUEST:
            return { loading: true };
        case GET_REGISTERED_USERS_SUCCESS:
            return {
                loading: false,
                rawRegisteredUsers: action.payload,
                registeredUsers: action.payload,
            };
        case GET_REGISTERED_USERS_FILTER:
            return {
                ...state,
                loading: false,
                registeredUsers: action.payload,
            };
        case GET_REGISTERED_USERS_CLEAR:
            return {
                ...state,
                registeredUsers: state.rawRegisteredUsers,
            };
        case GET_REGISTERED_USERS_FAIL:
            return { loading: false, error: action.payload };
        case GET_REGISTERED_USERS_RESET:
            return {};
        default:
            return state;
    }
};

export const signupLocationReducer = (state = {}, action) => {
    switch (action.type) {
        case SIGNIN_ADDRESS_REQUEST:
            return { loading: true };
        case SIGNIN_ADDRESS_SUCCESS:
            return { loading: false, success: true };
        case SIGNIN_ADDRESS_FAIL:
            return { loading: false, error: action.payload };
        case SIGNIN_ADDRESS_RESET:
            return {};
        default:
            return state;
    }
};

export const currentUserLocationReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_CURRENT_USER_LOCATION_REQUEST:
            return { loading: true };
        case GET_CURRENT_USER_LOCATION_SUCCESS:
            return { loading: false, currentLocation: action.payload };
        case GET_CURRENT_USER_LOCATION_FAIL:
            return { loading: false, error: action.payload };
        case GET_CURRENT_USER_LOCATION_RESET:
            return {};
        default:
            return state;
    }
};

export const updateCurrentUserLocationReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_CURRENT_USER_LOCATION_REQUEST:
            return { loading: true };
        case UPDATE_CURRENT_USER_LOCATION_SUCCESS:
            return { loading: false, success: true };
        case UPDATE_CURRENT_USER_LOCATION_FAIL:
            return { loading: false, error: action.payload };
        case UPDATE_CURRENT_USER_LOCATION_RESET:
            return {};
        default:
            return state;
    }
};

export const currentUserSalesPostsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_CURRENT_SALES_POSTS_REQUEST:
            return { loading: true };
        case GET_CURRENT_SALES_POSTS_SUCCESS:
            return { loading: false, salesPosts: action.payload };
        case GET_CURRENT_SALES_POSTS_FAIL:
            return { loading: false, error: action.payload };
        case GET_CURRENT_SALES_POSTS_RESET:
            return {};
        default:
            return state;
    }
};

export const updateCurrentUserSalesPostsReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_CURRENT_SALES_POSTS_REQUEST:
            return { loading: true };
        case UPDATE_CURRENT_SALES_POSTS_SUCCESS:
            return { loading: false, success: true };
        case UPDATE_CURRENT_SALES_POSTS_FAIL:
            return { loading: false, error: action.payload };
        case UPDATE_CURRENT_SALES_POSTS_RESET:
            return {};
        default:
            return state;
    }
};

export const queryClaimsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_CLAIMS_REQUEST:
            return { loading: true };
        case GET_CLAIMS_SUCCESS:
            return { loading: false, claims: action.payload };
        case GET_CLAIMS_FAIL:
            return { loading: false, error: action.payload };
        case GET_CLAIMS_RESET:
            return {};
        default:
            return state;
    }
};

export const updateClaimsReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_CLAIMS_REQUEST:
            return { loading: true };
        case UPDATE_CLAIMS_SUCCESS:
            return {
                loading: false,
                claims: action.payload.claims,
                message: action.payload.message,
                success: true,
            };
        case UPDATE_CLAIMS_FAIL:
            return { loading: false, error: action.payload };
        case UPDATE_CLAIMS_RESET:
            return {};
        default:
            return state;
    }
};

export const checkIfUserExistsReducer = (state = {}, action) => {
    switch (action.type) {
        case CHECK_IF_USER_EXISTS_REQUEST:
            return { loading: true };
        case CHECK_IF_USER_EXISTS_SUCCESS:
            return { loading: false, user: action.payload };
        case CHECK_IF_USER_EXISTS_FAIL:
            return { loading: false, error: action.payload };
        case CHECK_IF_USER_EXISTS_RESET:
            return {};
        default:
            return state;
    }
};

export const emailResponseReducer = (state = {}, action) => {
    switch (action.type) {
        case SEND_EMAIL_REQUEST:
            return { loading: true };
        case SEND_EMAIL_SUCCESS:
            return { loading: false, response: action.payload };
        case SEND_EMAIL_FAIL:
            return { loading: false, error: action.payload };
        case SEND_EMAIL_RESET:
            return {};
        default:
            return state;
    }
};

export const addMeetingRequestReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_MEETINGS_REQUEST:
            return { loading: true };
        case ADD_MEETINGS_SUCCESS:
            return {
                loading: false,
                success: true,
                meeting: action.payload,
            };
        case ADD_MEETINGS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const meetingDeletionReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_MEETING_REQUEST:
            return { loading: true };
        case DELETE_MEETING_SUCCESS:
            return { loading: false, success: true };
        case DELETE_MEETING_FAIL:
            return { loading: false, error: action.payload };
        case DELETE_MEETING_RESET:
            return {};
        default:
            return state;
    }
};
