import {
    GET_ADMIN_DASH_FAIL,
    GET_ADMIN_DASH_REQUEST,
    GET_ADMIN_DASH_RESET,
    GET_ADMIN_DASH_SUCCESS,
} from '../constants/adminDashConstants';

export const adminDashboardReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ADMIN_DASH_REQUEST:
            return { loading: true };
        case GET_ADMIN_DASH_SUCCESS:
            return { loading: false, dashSnap: action.payload };
        case GET_ADMIN_DASH_FAIL:
            return { loading: false, error: action.payload };
        case GET_ADMIN_DASH_RESET:
            return {};
        default:
            return state;
    }
};
