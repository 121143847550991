export const TRAINING_IMAGE_UPLOAD_REQUEST = 'TRAINING_IMAGE_UPLOAD_REQUEST';
export const TRAINING_IMAGE_UPLOAD_SUCCESS = 'TRAINING_IMAGE_UPLOAD_SUCCESS';
export const TRAINING_IMAGE_UPLOAD_FAIL = 'TRAINING_IMAGE_UPLOAD_FAIL';
export const TRAINING_IMAGE_UPLOAD_RESET = 'TRAINING_IMAGE_UPLOAD_RESET';

export const GET_TRAINING_DATA_REQUEST = 'GET_TRAINING_DATA_REQUEST';
export const GET_TRAINING_DATA_SUCCESS = 'GET_TRAINING_DATA_SUCCESS';
export const GET_TRAINING_DATA_FAIL = 'GET_TRAINING_DATA_FAIL';
export const GET_TRAINING_DATA_RESET = 'GET_TRAINING_DATA_RESET';

export const UPDATE_TRAINING_DATA_REQUEST = 'UPDATE_TRAINING_DATA_REQUEST';
export const UPDATE_TRAINING_DATA_SUCCESS = 'UPDATE_TRAINING_DATA_SUCCESS';
export const UPDATE_TRAINING_DATA_FAIL = 'UPDATE_TRAINING_DATA_FAIL';
export const UPDATE_TRAINING_DATA_RESET = 'UPDATE_TRAINING_DATA_RESET';

export const SAVE_SWIPE_LABELS_REQUEST = 'SAVE_SWIPE_LABELS_REQUEST';
export const SAVE_SWIPE_LABELS_SUCCESS = 'SAVE_SWIPE_LABELS_SUCCESS';
export const SAVE_SWIPE_LABELS_FAIL = 'SAVE_SWIPE_LABELS_FAIL';
export const SAVE_SWIPE_LABELS_RESET = 'SAVE_SWIPE_LABELS_RESET';
