import React, { useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';

import {
    EmailAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    updateProfile,
    sendPasswordResetEmail,
    reauthenticateWithCredential,
    updateEmail,
    updatePassword,
} from 'firebase/auth';
import { ref, set, serverTimestamp } from 'firebase/database';
import { formatEmail } from '../functions/formatEmail';
import { getErrorMessage } from '../firebase/functions/generalFunctions';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [userType, setUserType] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [justRegistered, setJustRegistered] = useState(false);

    function signup(email, password, userInfo) {
        const registerUser = async () => {
            try {
                const createUser = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );

                const user = createUser.user;
                const userID = user.uid;
                await updateProfile(auth.currentUser, {
                    displayName: userInfo.firstName + ' ' + userInfo.lastName,
                });
                await set(ref(db, 'Registration/' + userID), {
                    ...userInfo,
                    email: formatEmail(email),
                    userType: Number(userInfo.userType),
                    registeredAt: serverTimestamp(),
                });
                setError('');
                setJustRegistered(true);
            } catch (error) {
                console.log(error);
                const message = getErrorMessage(error);
                if (
                    message.includes('email') &&
                    message.includes('already') &&
                    message.includes('in') &&
                    message.includes('use')
                ) {
                    setError(
                        'This email is already in use, please sign in or use a different email.'
                    );
                }
            }
        };
        return registerUser();
    }

    function login(email, password) {
        const formattedEmail = formatEmail(email);
        return signInWithEmailAndPassword(auth, formattedEmail, password);
    }

    function logout() {
        return auth.signOut();
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(auth, email);
    }

    function reauthenticate(email, password) {
        console.log('reauthenticating');
        const credential = EmailAuthProvider.credential(email, password);
        return reauthenticateWithCredential(auth.currentUser, credential);
    }

    function emailUpdate(email) {
        console.log('updating email');
        return updateEmail(auth.currentUser, email);
    }

    function passwordUpdate(password) {
        return updatePassword(auth.currentUser, password);
    }

    function setValue(value) {
        return set(ref(db, 'Registration/' + currentUser.uid), value);
    }

    async function addUserType(user) {
        const result = await user.getIdTokenResult(true);
        var uType = {
            isFarmer: result.claims.isFarmer,
            isDriver: result.claims.isDriver,
            isTrader: result.claims.isTrader,
            countryCode: result.claims.countryCode
                ? result.claims.countryCode
                : 'ZW',
        };
        if (result.claims.isAdmin) {
            uType = {
                ...uType,
                isAdmin: result.claims.isAdmin,
            };
        }
        if (result.claims.isGrader) {
            uType = {
                ...uType,
                isGrader: result.claims.isGrader,
            };
        }
        setUserType(uType);
        setCurrentUser(user);
        setLoading(false);
        if (
            typeof uType.isFarmer !== 'undefined' &&
            typeof uType.isDriver !== 'undefined' &&
            typeof uType.isTrader !== 'undefined'
        ) {
            setJustRegistered(false);
        }
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            console.log(user);
            if (user) {
                addUserType(user);
            } else {
                setLoading(false);
                setCurrentUser(null);
                setUserType(null);
            }
        });

        return unsubscribe;
    }, []);

    useEffect(() => {
        if (
            currentUser &&
            justRegistered &&
            typeof userType.isFarmer === 'undefined' &&
            typeof userType.isDriver === 'undefined' &&
            typeof userType.isTrader === 'undefined'
        ) {
            setTimeout(() => {
                console.log('requesting userType');
                addUserType(currentUser);
            }, 3000);
        }
    }, [currentUser, justRegistered, userType]);

    const value = {
        currentUser,
        userType,
        loading,
        error,
        login,
        signup,
        logout,
        resetPassword,
        reauthenticate,
        emailUpdate,
        passwordUpdate,
        setValue,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
