export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST';
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_IMAGE_URLS = 'PRODUCT_DETAILS_IMAGE_URLS';
export const PRODUCT_SAVE_CROP_DETAILS = 'PRODUCT_SAVE_CROP_DETAILS';
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL';

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST';
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL';
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';
export const PRODUCT_DELETE_RESET = 'PRODUCT_DELETE_RESET';

export const PRODUCT_IMAGE_UPLOAD_REQUEST = 'PRODUCT_IMAGE_UPLOAD_REQUEST';
export const PRODUCT_IMAGE_UPLOAD_SUCCESS = 'PRODUCT_IMAGE_UPLOAD_SUCCESS';
export const PRODUCT_IMAGE_UPLOAD_FAIL = 'PRODUCT_IMAGE_UPLOAD_FAIL';
export const PRODUCT_IMAGE_UPLOAD_RESET = 'PRODUCT_IMAGE_UPLOAD_RESET';

export const PRODUCT_IMAGE_LIST_REQUEST = 'PRODUCT_IMAGE_LIST_REQUEST';
export const PRODUCT_IMAGE_LIST_SUCCESS = 'PRODUCT_IMAGE_LIST_SUCCESS';
export const PRODUCT_IMAGE_LIST_FAIL = 'PRODUCT_IMAGE_LIST_FAIL';
export const PRODUCT_IMAGE_LIST_RESET = 'PRODUCT_IMAGE_LIST_RESET';

export const PRODUCT_IMAGE_DELETE_REQUEST = 'PRODUCT_IMAGE_DELETE_REQUEST';
export const PRODUCT_IMAGE_DELETE_SUCCESS = 'PRODUCT_IMAGE_DELETE_SUCCESS';
export const PRODUCT_IMAGE_DELETE_FAIL = 'PRODUCT_IMAGE_DELETE_FAIL';
export const PRODUCT_IMAGE_DELETE_RESET = 'PRODUCT_IMAGE_DELETE_RESET';
