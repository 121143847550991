import {
    GET_VERSION_FAIL,
    GET_VERSION_REQUEST,
    GET_VERSION_RESET,
    GET_VERSION_SUCCESS,
    NOTIFICATIONS_UPDATE_FAIL,
    NOTIFICATIONS_UPDATE_REQUEST,
    NOTIFICATIONS_UPDATE_RESET,
    NOTIFICATIONS_UPDATE_SUCCESS,
    NOTIFICATION_CREATE_FAIL,
    NOTIFICATION_CREATE_REQUEST,
    NOTIFICATION_CREATE_RESET,
    NOTIFICATION_CREATE_SUCCESS,
    NOTIFICATION_LIST_FAIL,
    NOTIFICATION_LIST_REQUEST,
    NOTIFICATION_LIST_RESET,
    NOTIFICATION_LIST_SUCCESS,
} from '../constants/notificationsConstants';

export const userTokenCreationReducer = (state = {}, action) => {
    switch (action.type) {
        case NOTIFICATION_CREATE_REQUEST:
            return { loading: true };
        case NOTIFICATION_CREATE_SUCCESS:
            return { loading: false, success: true };
        case NOTIFICATION_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case NOTIFICATION_CREATE_RESET:
            return {};
        default:
            return state;
    }
};

export const notificationListReducer = (
    state = { notifications: [] },
    action
) => {
    switch (action.type) {
        case NOTIFICATION_LIST_REQUEST:
            return { loading: true };
        case NOTIFICATION_LIST_SUCCESS:
            return {
                loading: false,
                notifications: action.payload.notifications,
            };
        case NOTIFICATION_LIST_FAIL:
            return { loading: false, error: action.payload };
        case NOTIFICATION_LIST_RESET:
            return { notifications: [], loading: false };
        default:
            return state;
    }
};

export const notificationsUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case NOTIFICATIONS_UPDATE_REQUEST:
            return { loading: true };
        case NOTIFICATIONS_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case NOTIFICATIONS_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case NOTIFICATIONS_UPDATE_RESET:
            return {};
        default:
            return state;
    }
};

export const currentVersionReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_VERSION_REQUEST:
            return { loading: true };
        case GET_VERSION_SUCCESS:
            return { loading: false, version: action.payload };
        case GET_VERSION_FAIL:
            return { loading: false, error: action.payload };
        case GET_VERSION_RESET:
            return {};
        default:
            return state;
    }
};
