import {
    GET_VERSION_FAIL,
    GET_VERSION_REQUEST,
    GET_VERSION_SUCCESS,
    NOTIFICATIONS_UPDATE_FAIL,
    NOTIFICATIONS_UPDATE_REQUEST,
    NOTIFICATIONS_UPDATE_SUCCESS,
    NOTIFICATION_CREATE_FAIL,
    NOTIFICATION_CREATE_REQUEST,
    NOTIFICATION_CREATE_SUCCESS,
    NOTIFICATION_LIST_FAIL,
    NOTIFICATION_LIST_REQUEST,
    NOTIFICATION_LIST_SUCCESS,
} from '../constants/notificationsConstants';

import { db } from '../../firebase';

import {
    ref,
    get,
    onValue,
    off,
    serverTimestamp,
    update,
} from 'firebase/database';
import {
    getNotificationTokenPath,
    getUserNotificationsPath,
    getUserTokensPath,
} from '../../functions/getPaths';
import { getUserTypeNumber } from '../../functions/getUser';
import { getErrorMessage } from '../functions/generalFunctions';

export const createUserToken =
    (userToken, user, userType, updateBool = false) =>
    async (dispatch, getState) => {
        dispatch({ type: NOTIFICATION_CREATE_REQUEST });

        try {
            const rootRef = ref(db, '/');

            var updates = {};
            const tokenPath = getNotificationTokenPath(userToken);

            if (!updateBool) {
                updates[`${tokenPath}/createdAt`] = serverTimestamp();
            }
            updates[`${tokenPath}/uid`] = user.uid;
            updates[`${tokenPath}/userType`] = getUserTypeNumber(userType);
            updates[`${tokenPath}/updatedAt`] = serverTimestamp();

            updates[getUserTokensPath(user.uid, userType) + userToken] =
                serverTimestamp();

            await update(rootRef, updates);
            dispatch({ type: NOTIFICATION_CREATE_SUCCESS });
        } catch (error) {
            const message =
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message;
            dispatch({ type: NOTIFICATION_CREATE_FAIL, payload: message });
        }
    };

export const listNotificationsCallback = (user) => (dispatch, getState) => {
    const notificationRef = ref(db, getUserNotificationsPath(user.uid));

    const callback = async (snapshot) => {
        var formattedData = [];
        const notifications = snapshot.val();
        if (notifications) {
            const idArray = Object.keys(notifications);
            var objectArray = Object.values(notifications);
            formattedData = await objectArray.map(function (el, i) {
                var o = Object.assign({}, el);
                o._id = idArray[i];
                return o;
            });
            if (formattedData.length !== 0) {
                formattedData.sort((a, b) =>
                    Number(a.data.timestamp) < Number(b.data.timestamp)
                        ? 1
                        : Number(b.data.timestamp) < Number(a.data.timestamp)
                        ? -1
                        : 0
                );
            }
        }
        dispatch({
            type: NOTIFICATION_LIST_SUCCESS,
            payload: { notifications: formattedData },
        });
    };

    const errorHandler = (error) => {
        dispatch({ type: NOTIFICATION_LIST_FAIL, payload: error.message });
    };

    onValue(notificationRef, callback, errorHandler);

    return () => {
        off(notificationRef, callback);
    };
};

export const listNotifications =
    (notifications) => async (dispatch, getState) => {
        dispatch({
            type: NOTIFICATION_LIST_REQUEST,
        });

        try {
            var formattedData = [];
            const idArray = Object.keys(notifications);
            var objectArray = Object.values(notifications);
            formattedData = await objectArray.map(function (el, i) {
                var o = Object.assign({}, el);
                o._id = idArray[i];
                return o;
            });
            if (formattedData.length !== 0) {
                formattedData.sort((a, b) =>
                    Number(a.data.timestamp) < Number(b.data.timestamp)
                        ? 1
                        : Number(b.data.timestamp) < Number(a.data.timestamp)
                        ? -1
                        : 0
                );
            }

            dispatch({
                type: NOTIFICATION_LIST_SUCCESS,
                payload: { notifications: formattedData },
            });
        } catch (error) {
            dispatch({
                type: NOTIFICATION_LIST_FAIL,
                payload: getErrorMessage(error),
            });
        }
    };

export const updateNotification =
    (notificationId, user, readOrClosed) => async (dispatch) => {
        dispatch({
            type: NOTIFICATIONS_UPDATE_REQUEST,
            payload: notificationId,
        });

        try {
            const rootRef = ref(db, '/');
            var updates = {};
            const notificationPath = getUserNotificationsPath(user.uid);
            updates[
                `${notificationPath}/${notificationId}/data/${readOrClosed}`
            ] = serverTimestamp();

            await update(rootRef, updates);
            dispatch({
                type: NOTIFICATIONS_UPDATE_SUCCESS,
            });
        } catch (error) {
            console.log('Failed Here');
            console.log(error);
            const message =
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message;
            dispatch({ type: NOTIFICATIONS_UPDATE_FAIL, payload: message });
        }
    };

export const getServerVersion = () => async (dispatch) => {
    dispatch({ type: GET_VERSION_REQUEST, payload: '0.1.0' });
    try {
        const versionRef = ref(db, '/Versions/UI');
        const snapshot = await get(versionRef);

        dispatch({ type: GET_VERSION_SUCCESS, payload: snapshot.val() });
    } catch (error) {
        dispatch({
            type: GET_VERSION_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
