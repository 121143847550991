import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    footer: {
        gridArea: 'footer',
        backgroundColor: '#151619',
        color: '#717d86',
        padding: '50px, 0',
    },

    footerRowCenter: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },

    footerContent: {
        textAlign: 'center',
        minWidth: '15rem',
        padding: '1rem',
    },

    siteFooterInner: {
        padding: '48px',
    },

    footerTop: {
        paddingBottom: '12px',
    },

    footerBottom: {
        paddingTop: '12px',
    },

    footerBlocks: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: '-24px',
        marginLeft: '-24px',
        marginTop: '-24px',
        marginBottom: '12-px',
    },

    footerBlock: {
        flexGrow: '1',
        flexBasis: '160px',
        boxSizing: 'content-box',
        padding: '12px 24px',

        ul: {
            '&:not(:last-child)': {
                marginBottom: '4px',
            },
        },
        li: {
            '&:not(:last-child)': {
                marginBottom: '4px',
            },
        },
    },

    footerBlockTitle: {
        color: '#9ca9b3',
        textTransform: 'uppercase',
        marginBottom: '8px',
    },

    footerNav: {
        color: '#9ca9b3',
        textTransform: 'uppercase',
        marginBottom: '8px',
        '& a': {
            display: 'flex',
            textTransform: 'null',
            padding: '0 12px',
        },

        ul: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            marginBottom: 0,
        },
        marginRight: '-12px',
        marginLeft: '-12px',
    },

    footerSocial: {
        color: 'white',
        '& ul': {
            marginRight: 0,
            marginLeft: 0,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            marginBottom: 0,
        },

        '& li': {
            padding: '0 12px',
        },

        '& a': {
            padding: '8px',
            display: 'flex',
        },
    },

    listReset: {
        listStyle: 'none',
        padding: 0,
    },
}));

export default useStyles;
