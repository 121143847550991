import { CircularProgress, Typography } from '@mui/material';
import React from 'react';

import { Box } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    loading: {
        height: '5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.1rem',
        flexDirection: 'row',
    },
    message: {
        padding: '0.1rem',
    },
    loadingBox: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(4),
        padding: theme.spacing(4),
    },
}));

export default function LoadingBox({ loading }) {
    const classes = useStyles();
    return (
        <Box className={classes.loadingBox}>
            <Box className={classes.loading}>
                <CircularProgress size="3rem" sx={{ m: 2 }} />
                <Typography variant="h3" className={classes.message}>
                    {loading}
                </Typography>
            </Box>
        </Box>
    );
}
