import React, { lazy, Suspense, useEffect } from 'react';

import { ThemeProvider, CssBaseline } from '@mui/material';

import { Route, useLocation } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext';

import theme from './appTheme';
import RenderRoute from './components/Routes/RenderRoute';
import PrivateRoute from './components/Routes/PrivateRoute';
import AdminRoute from './components/Routes/AdminRoute';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Notification from './components/Notifications/Notification';
import Update from './components/Notifications/Update';

const HomeScreen = lazy(() => import('./screens/HomeScreen'));
const SignInScreen = lazy(() => import('./screens/SignInScreen'));
const SignUpScreen = lazy(() => import('./screens/SignUpScreen'));
const PasswordResetScreen = lazy(() => import('./screens/PasswordResetScreen'));
const ChangeEmailScreen = lazy(() => import('./screens/ChangeEmailScreen'));
const ChangePasswordScreen = lazy(() =>
    import('./screens/ChangePasswordScreen')
);
const ContactUsScreen = lazy(() => import('./screens/ContactUsScreen'));
const RequestScreen = lazy(() => import('./screens/RequestScreen'));
const PricingScreen = lazy(() => import('./screens/PricingScreen'));
const MapScreen = lazy(() => import('./screens/MapScreen'));
const SalesListScreen = lazy(() => import('./screens/SalesListScreen'));
const MyProfileScreen = lazy(() => import('./screens/MyProfileScreen'));
const ProfileScreen = lazy(() => import('./screens/ProfileScreen'));
const ProductEditScreen = lazy(() => import('./screens/ProductEditScreen'));
const ProductScreen = lazy(() => import('./screens/ProductScreen'));
const CartScreen = lazy(() => import('./screens/CartScreen'));
const CheckoutScreen = lazy(() => import('./screens/CheckoutScreen'));
const OrderListScreen = lazy(() => import('./screens/OrderListScreen'));
const OrderScreen = lazy(() => import('./screens/OrderScreen'));
const InvoiceScreen = lazy(() => import('./screens/InvoiceScreen'));
const FreshHotScreen = lazy(() => import('./screens/FreshHotScreen'));
const MessageScreen = lazy(() => import('./screens/MessageScreen'));
const DemoScreen = lazy(() => import('./screens/DemoScreen'));
const GradingAdviceScreen = lazy(() => import('./screens/GradingAdviceScreen'));
const FeedbackListScreen = lazy(() => import('./screens/FeedbackListScreen'));
const FeedbackVisualiseScreen = lazy(() =>
    import('./screens/FeedbackVisualiseScreen')
);
const LandingPageScreen = lazy(() => import('./screens/LandingPageScreen'));
const ModelsScreen = lazy(() => import('./screens/ModelsScreen'));
const ModelScreen = lazy(() => import('./screens/ModelScreen'));

const AdminScreen = lazy(() => import('./screens/AdminScreen'));
const NewUsersScreen = lazy(() =>
    import('./screens/AdminScreens/NewUsersScreen')
);
const CropTypesScreen = lazy(() =>
    import('./screens/AdminScreens/CropTypesScreen')
);
const ContactUsersScreen = lazy(() =>
    import('./screens/AdminScreens/ContactUsersScreen')
);
const EditUsersScreen = lazy(() =>
    import('./screens/AdminScreens/EditUsersScreen')
);
const AddPlaceScreen = lazy(() =>
    import('./screens/AdminScreens/AddPlaceScreen')
);

const AdminEditUserLocationScreen = lazy(() =>
    import('./screens/AdminScreens/AdminEditUserLocationScreen')
);

const AdminProductEditScreen = lazy(() =>
    import('./screens/AdminScreens/AdminProductEditScreen')
);

const AdminCreateUserScreen = lazy(() =>
    import('./screens/AdminScreens/AdminCreateUserScreen')
);

const AdminDashboardScreen = lazy(() =>
    import('./screens/AdminScreens/AdminDashboardScreen')
);

const UploadFreshHotScreen = lazy(() =>
    import('./screens/AdminScreens/UploadFreshHotScreen')
);

const EmailUsersScreen = lazy(() =>
    import('./screens/AdminScreens/EmailUsersScreen')
);

const GetStartedHelpScreen = lazy(() =>
    import('./screens/HelpScreens/GetStartedHelpScreen')
);
const ProfileSetupHelpScreen = lazy(() =>
    import('./screens/HelpScreens/ProfileSetupHelpScreen')
);

const ListProduceHelpScreen = lazy(() =>
    import('./screens/HelpScreens/ListProduceHelpScreen')
);

const ManageMeetingsScreen = lazy(() =>
    import('./screens/AdminScreens/ManageMeetingsScreen')
);
const AddMeeting = lazy(() => import('./screens/AdminScreens/AddMeeting'));
const MeetingsScreen = lazy(() => import('./screens/MeetingsScreen'));

const App = () => {
    let location = useLocation();

    useEffect(() => {
        document.body.classList.add('is-loaded');
        window.gtag('event', 'page_view', {
            page_path: location.pathname + location.search + location.hash,
            page_search: location.search,
            page_hash: location.hash,
        });
    }, [location]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
                <Suspense fallback={<div>Loading...</div>}>
                    <Notification />
                    <Update />
                    <RenderRoute component={Header}></RenderRoute>
                    <Route path="/" component={HomeScreen} exact></Route>
                    <Route path="/signin" component={SignInScreen}></Route>
                    <Route path="/signup" component={RequestScreen}></Route>
                    <Route path="/register" component={SignUpScreen}></Route>
                    <Route path="/pricing" component={PricingScreen}></Route>
                    <Route
                        path="/password-reset"
                        component={PasswordResetScreen}
                    ></Route>
                    <Route
                        path="/contact-us"
                        component={ContactUsScreen}
                    ></Route>

                    <Route path="/demo" component={DemoScreen}></Route>

                    <Route
                        path="/landing-page/:pid"
                        component={LandingPageScreen}
                    ></Route>

                    <Route path="/models" component={ModelsScreen}></Route>
                    <Route path="/model/:mid" component={ModelScreen}></Route>

                    <Route path="/meetings" component={MeetingsScreen}></Route>

                    <PrivateRoute
                        path="/change-email"
                        component={ChangeEmailScreen}
                    ></PrivateRoute>
                    <PrivateRoute
                        path="/change-password"
                        component={ChangePasswordScreen}
                    ></PrivateRoute>

                    <Route
                        path="/product/:id/vtype/:vt"
                        component={ProductScreen}
                        exact
                    ></Route>
                    <PrivateRoute
                        path="/cart/:id?"
                        component={CartScreen}
                    ></PrivateRoute>
                    <PrivateRoute
                        path="/checkout"
                        component={CheckoutScreen}
                    ></PrivateRoute>

                    <PrivateRoute
                        path="/product/:id/edit"
                        component={ProductEditScreen}
                        exact
                    ></PrivateRoute>

                    <PrivateRoute
                        path="/purchase/:id/edit"
                        component={ProductEditScreen}
                        exact
                    ></PrivateRoute>

                    <PrivateRoute
                        path="/product/:id/give-grading-advice"
                        component={GradingAdviceScreen}
                        exact
                    ></PrivateRoute>

                    <PrivateRoute
                        path="/product/:id/grading-advice"
                        component={FeedbackListScreen}
                        exact
                    ></PrivateRoute>

                    <PrivateRoute
                        path="/product/:id/grading-advice/:gid"
                        component={FeedbackVisualiseScreen}
                        exact
                    ></PrivateRoute>

                    <PrivateRoute
                        path="/message-centre"
                        component={MessageScreen}
                        exact
                    ></PrivateRoute>
                    <PrivateRoute
                        path="/message-centre/new-message/:id"
                        component={MessageScreen}
                        exact
                    ></PrivateRoute>
                    <PrivateRoute
                        path="/my-profile"
                        component={MyProfileScreen}
                    ></PrivateRoute>
                    <PrivateRoute
                        path="/profile/:ut/:id"
                        component={ProfileScreen}
                    ></PrivateRoute>
                    <PrivateRoute
                        path="/orders"
                        component={OrderListScreen}
                    ></PrivateRoute>
                    <PrivateRoute
                        path="/order/:id"
                        component={OrderScreen}
                    ></PrivateRoute>
                    <PrivateRoute
                        path="/invoice/:id"
                        component={InvoiceScreen}
                    ></PrivateRoute>
                    <PrivateRoute
                        path="/sales/:seller"
                        component={SalesListScreen}
                    ></PrivateRoute>
                    <PrivateRoute
                        path="/purchasing/:buyer"
                        component={SalesListScreen}
                    ></PrivateRoute>
                    <PrivateRoute
                        path="/map"
                        component={MapScreen}
                    ></PrivateRoute>
                    <PrivateRoute
                        path="/grading"
                        component={FreshHotScreen}
                    ></PrivateRoute>

                    <AdminRoute
                        path="/admin-dashboard"
                        component={AdminDashboardScreen}
                    ></AdminRoute>

                    <AdminRoute
                        path="/admin"
                        component={AdminScreen}
                    ></AdminRoute>
                    <AdminRoute
                        path="/crop-types"
                        component={CropTypesScreen}
                    ></AdminRoute>
                    <AdminRoute
                        path="/new-users"
                        component={NewUsersScreen}
                    ></AdminRoute>
                    <AdminRoute
                        path="/edit-users/"
                        exact
                        component={EditUsersScreen}
                    ></AdminRoute>
                    <AdminRoute
                        path="/edit-users/:uid"
                        exact
                        component={EditUsersScreen}
                    ></AdminRoute>

                    <AdminRoute
                        path="/contact-users"
                        component={ContactUsersScreen}
                    />
                    <AdminRoute path="/add-place" component={AddPlaceScreen} />

                    <AdminRoute
                        path="/upload-training"
                        component={UploadFreshHotScreen}
                    />

                    <AdminRoute
                        path="/admin-location-edit/:uid/:ut"
                        component={AdminEditUserLocationScreen}
                    />

                    <AdminRoute
                        exact
                        path="/admin-product-edit/:uid/:pid/:ut"
                        component={AdminProductEditScreen}
                    />
                    <AdminRoute
                        path="/admin-create-new-user"
                        component={AdminCreateUserScreen}
                    />
                    <AdminRoute
                        path="/admin-email-users"
                        component={EmailUsersScreen}
                    />
                    <AdminRoute path="/add-meeting" component={AddMeeting} />
                    <AdminRoute
                        path="/manage-meetings"
                        component={ManageMeetingsScreen}
                    />

                    <Route
                        path="/get-started"
                        component={GetStartedHelpScreen}
                    />
                    <Route
                        path="/help-topic/help-register"
                        component={GetStartedHelpScreen}
                    />

                    <Route
                        path="/help-topic/help-profile-setup"
                        component={ProfileSetupHelpScreen}
                    />

                    <Route
                        path="/help-topic/help-sell-your-produce"
                        component={ListProduceHelpScreen}
                    />

                    <RenderRoute component={Footer}></RenderRoute>
                </Suspense>
            </AuthProvider>
        </ThemeProvider>
    );
};
export default App;
