export const CHAT_LIST_REQUEST = 'CHAT_LIST_REQUEST';
export const CHAT_LIST_SUCCESS = 'CHAT_LIST_SUCCESS';
export const CHAT_LIST_FAIL = 'CHAT_LIST_FAIL';
export const CHAT_LIST_RESET = 'CHAT_LIST_RESET';

export const MESSAGE_LIST_REQUEST = 'MESSAGE_LIST_REQUEST';
export const MESSAGE_LIST_SUCCESS = 'MESSAGE_LIST_SUCCESS';
export const MESSAGE_LIST_FAIL = 'MESSAGE_LIST_FAIL';
export const MESSAGE_LIST_RESET = 'MESSAGE_LIST_RESET';

export const MESSAGE_SEND_REQUEST = 'MESSAGE_SEND_REQUEST';
export const MESSAGE_SEND_SUCCESS = 'MESSAGE_SEND_SUCCESS';
export const MESSAGE_SEND_FAIL = 'MESSAGE_SEND_FAIL';
export const MESSAGE_SEND_RESET = 'MESSAGE_SEND_RESET';

export const MESSAGE_SEEN = 'MESSAGE_SEEN';

export const CHAT_CREATE_REQUEST = 'CHAT_CREATE_REQUEST';
export const CHAT_CREATE_SUCCESS = 'CHAT_CREATE_SUCCESS';
export const CHAT_CREATE_FAIL = 'CHAT_CREATE_FAIL';
export const CHAT_CREATE_RESET = 'CHAT_CREATE_RESET';
