import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function ForwardClickButton({ handleClick, toolTip, color }) {
    return (
        <Tooltip title={toolTip} arrow>
            <IconButton
                color={color}
                aria-label={toolTip}
                onClick={handleClick}
            >
                <ArrowForwardIosIcon />
            </IconButton>
        </Tooltip>
    );
}
