import React from 'react';
import useStyles from './styles.js';
import FacebookIcon from './SocialIcons/FacebookIcon.jsx';
import TwitterIcon from './SocialIcons/TwitterIcon.jsx';
import InstagramIcon from './SocialIcons/InstagramIcon.jsx';
import LinkedinIcon from './SocialIcons/LinkedinIcon.jsx';

export default function FooterSocial() {
    const classes = useStyles();
    return (
        <div className={classes.footerSocial}>
            <ul className={classes.listReset}>
                <li>
                    <FacebookIcon />
                </li>
                <li>
                    <TwitterIcon />
                </li>
                <li>
                    <InstagramIcon />
                </li>
                <li>
                    <LinkedinIcon />
                </li>
            </ul>
        </div>
    );
}
