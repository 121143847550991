import {
    Avatar,
    CardMedia,
    IconButton,
    Paper,
    Snackbar,
    Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import drop from '../../assets/drop.m4a';
import glass from '../../assets/smallglass.m4a';
import { getNotificationDate } from '../../functions/dateFromatting';
import { useHistory, useLocation } from 'react-router-dom';
import runOneSignal from '../../onesignal';
import { useAuth } from '../../contexts/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { updateNotification } from '../../firebase/actions/notificationActions';
import { NOTIFICATIONS_UPDATE_RESET } from '../../firebase/constants/notificationsConstants';

export default function Notification() {
    const { currentUser, userType } = useAuth();
    let history = useHistory();
    let location = useLocation();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [notification, setNotification] = useState({
        content: {
            en: '',
        },
        headings: {
            en: '',
        },
        data: {
            timestamp: String(Date.now()),
            notificationId: '',
            link: 'https://market.kuronga.com',
            read: 'false',
            closed: 'false',
        },
        url: 'https://market.kuronga.com',
        chrome_web_icon:
            'https://firebasestorage.googleapis.com/v0/b/kuronga-prod.appspot.com/o/FCMImages%2Flogo256.png?alt=media&token=8d6d3cb3-d6ad-4530-81f6-0334763bdb44',
        chrome_web_image:
            'https://firebasestorage.googleapis.com/v0/b/kuronga-prod.appspot.com/o/FCMImages%2Flogo512.png?alt=media&token=02fa3267-02a1-4265-bdf2-f5840c057e9f',
        chrome_web_badge:
            'https://firebasestorage.googleapis.com/v0/b/kuronga-prod.appspot.com/o/FCMImages%2Fchrome_web_badge.png?alt=media&token=9ac2470d-8987-42f7-95d6-0a399b13a3fb',
    });

    const notificationList = useSelector((state) => state.notificationList);

    const { notifications, loading, error } = notificationList;

    const notificationsUpdate = useSelector(
        (state) => state.notificationsUpdate
    );

    const {
        success,
        loading: loadingUpdate,
        error: errorUpdate,
    } = notificationsUpdate;

    const play = (sound) => {
        new Audio(sound).play();
    };

    const handleClose = () => {
        play(drop);
        setOpen(false);
        dispatch(
            updateNotification(
                notification.data.notificationId,
                currentUser,
                'closed'
            )
        );
    };

    const handleClick = () => {
        play(glass);
        dispatch(
            updateNotification(
                notification.data.notificationId,
                currentUser,
                'read'
            )
        );
        setOpen(false);
        if (
            notification?.data?.link &&
            notification?.contents?.en !== 'Thanks for subscribing!'
        ) {
            const link = notification.data.link;
            const localLink = link.replace('https://market.kuronga.com', '');
            history.push(localLink);
        }
    };

    useEffect(() => {
        if (userType) {
            if (userType.isFarmer || userType.isTrader) {
                runOneSignal(userType);
            }
        }
    }, [userType]);

    useEffect(() => {
        if (notifications && notifications.length > 0 && !loading && !error) {
            const firstNotification = notifications[0];
            if (
                firstNotification?.data?.read === 'false' &&
                firstNotification?.data?.closed === 'false'
            ) {
                setNotification(firstNotification);
                setOpen(true);
                // play(glass);
            }
        }
    }, [notifications, loading, error]);

    useEffect(() => {
        if (location.search) {
            const params = new URLSearchParams(location.search);
            if (params.has('notification')) {
                const notificationId = params.get('notification');
                console.log('GOT notificationId');
                console.log(notificationId);
                dispatch(
                    updateNotification(notificationId, currentUser, 'read')
                );
            }
        }
    }, [dispatch, history, location.search, notification, currentUser]);

    useEffect(() => {
        if (success && !loadingUpdate && !errorUpdate) {
            dispatch({ type: NOTIFICATIONS_UPDATE_RESET });
            if (location.search) {
                const params = new URLSearchParams(location.search);
                if (params.has('redirect')) {
                    const redirectLink = params.get('redirect');
                    history.push(redirectLink);
                }
            }
        }
    }, [
        dispatch,
        location.search,
        history,
        success,
        loadingUpdate,
        errorUpdate,
    ]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={handleClose}
        >
            <Paper
                sx={{
                    m: 2,
                    p: 2,
                }}
            >
                <Stack direction="row">
                    {notification?.chrome_web_icon && (
                        <Avatar
                            sx={{ backgroundColor: 'white', mr: 2 }}
                            onClick={handleClick}
                        >
                            {
                                <CardMedia
                                    component="img"
                                    image={notification.chrome_web_icon}
                                    title="notification-image"
                                />
                            }
                        </Avatar>
                    )}
                    <Stack direction="column" onClick={handleClick}>
                        <Typography>
                            {notification?.headings?.en
                                ? notification.headings.en
                                : ''}
                        </Typography>
                        <Typography variant="caption">
                            {notification?.contents?.en
                                ? notification.contents.en
                                : ''}
                        </Typography>
                        <Typography variant="caption" color="secondary">
                            {getNotificationDate(
                                notification?.notification?.timestamp
                                    ? notification.notification.timestamp
                                    : Date.now()
                            )}
                        </Typography>
                    </Stack>
                    <IconButton
                        aria-label="close"
                        color="secondary"
                        sx={{ p: 0.5 }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </Paper>
        </Snackbar>
    );
}
