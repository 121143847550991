import React, { useEffect, useState } from 'react';

import { Box, Grid, IconButton, Link, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStyles from './styles.js';
import FooterSocial from './FooterSocial.jsx';
import Logo from '../Logo/Logo.jsx';
import theme from '../../appTheme.js';

function Copyright() {
    return (
        <Typography
            color="white"
            align="center"
            sx={{
                my: 4,
            }}
        >
            {'Copyright © '}
            <Link
                color="secondary"
                href="https://kuronga.com/"
                sx={{
                    textDecoration: 'none',
                    ':hover': { color: `${theme.palette.primary.main}` },
                }}
            >
                Kuronga Limited
            </Link>{' '}
            {new Date().getFullYear()}
            {'. | All rights reserved. '}
        </Typography>
    );
}

const footerLinks = [
    {
        name: 'About',
        linkType: 'external',
        link: 'https://kuronga.com/about-us',
    },
    {
        name: 'Contact',
        linkType: 'external',
        link: 'https://kuronga.com/contact-us',
    },
    { name: 'Pricing', linkType: 'internal', link: '/pricing' },
    { name: 'Feedback', linkType: 'internal', link: '/contact-us' },
    {
        name: 'Help',
        linkType: 'external',
        link: 'https://kuronga.com/docs/intro',
    },
];

function Footer(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (window.innerWidth > 600) {
            setOpen(true);
        }
    }, []);

    return (
        <Box sx={{ flexGrow: 1 }} className={classes.footer}>
            <IconButton
                onClick={() => setOpen(!open)}
                size="small"
                sx={{
                    color: '#eceded',
                    ':hover': { color: '#717d86' },
                }}
            >
                {open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
            {open && (
                <Grid container justifyContent="space-between">
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        justifyContent="center"
                        display="flex"
                    >
                        <Logo noBg={true} size="48" marginRight="0" />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        justifyContent="center"
                        display="flex"
                    >
                        <FooterSocial />
                    </Grid>
                </Grid>
            )}
            {open && (
                <Grid
                    container
                    className={`${classes.footerRowCenter} ${classes.footerNav}`}
                    justifyContent="space-between"
                >
                    {footerLinks.map((footerLink, i) => (
                        <Grid
                            item
                            key={i}
                            xs={6}
                            md={2}
                            justifyContent="center"
                            display="flex"
                        >
                            {footerLink.linkType === 'external' ? (
                                <Link
                                    sx={{
                                        textTransform: 'uppercase',
                                        textDecoration: 'none',
                                    }}
                                    href={footerLink.link}
                                >
                                    <Typography
                                        className={classes.footerContent}
                                        sx={{
                                            color: '#eceded',
                                            ':hover': { color: '#717d86' },
                                        }}
                                    >
                                        {footerLink.name}
                                    </Typography>
                                </Link>
                            ) : (
                                <Link
                                    component="button"
                                    onClick={() =>
                                        props.history.push(footerLink.link)
                                    }
                                    sx={{
                                        textTransform: 'uppercase',
                                        textDecoration: 'none',
                                    }}
                                >
                                    <Typography
                                        className={classes.footerContent}
                                        sx={{
                                            color: '#eceded',
                                            ':hover': { color: '#717d86' },
                                        }}
                                    >
                                        {footerLink.name}
                                    </Typography>
                                </Link>
                            )}
                        </Grid>
                    ))}
                </Grid>
            )}

            {open && (
                <div className={classes.footerRowCenter}>
                    <Copyright />
                </div>
            )}
        </Box>
    );
}

export default Footer;
