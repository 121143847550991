import React, { useEffect, useState } from 'react';
import {
    Button,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import CloseButton from '../Buttons/CloseButton';

import IosShareIcon from '@mui/icons-material/IosShare';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';

import NotificationsIcon from '@mui/icons-material/Notifications';
import showPrompt from '../../onesignal/showPrompt';

function getPWADisplayMode() {
    const isStandalone = window.matchMedia(
        '(display-mode: standalone)'
    ).matches;
    if (document.referrer.startsWith('android-app://')) {
        return 'twa';
    } else if (navigator.standalone || isStandalone) {
        return 'standalone';
    }
    return 'browser';
}

export default function InstallApp() {
    const [open, setOpen] = useState(false);
    const [viewInstall, setViewInstall] = useState(false);
    const [userInterface, setUserInterface] = useState('browser');

    useEffect(() => {
        const userView = getPWADisplayMode();
        setUserInterface(userView);
    }, []);

    const handleGetStarted = () => {
        setOpen(true);
        showPrompt(setOpen);
    };

    const handleClose = () => {
        setOpen(false);
        setViewInstall(false);
    };
    const handleInstall = () => {
        setViewInstall(true);
    };

    return (
        <>
            <Chip
                label="Subscribe"
                color="success"
                onClick={handleGetStarted}
                icon={<NotificationsIcon fontSize="small" />}
            />

            <Dialog open={open} onClose={handleClose}>
                <DialogActions>
                    <CloseButton handleClose={handleClose} />
                </DialogActions>
                {viewInstall ? (
                    <DialogContent>
                        <DialogTitle id="alert-dialog-title">
                            How to Install
                        </DialogTitle>
                        <DialogContentText sx={{ p: 2 }}>
                            In iOS tap share <IosShareIcon color="primary" />{' '}
                            and then <AddBoxOutlinedIcon color="primary" /> add
                            to home to install on your iPhone or iPad. Make sure
                            you are running iOS 16.4 or later.
                        </DialogContentText>
                        <DialogContentText sx={{ p: 2 }}>
                            In Android tap your browser menu icon{' '}
                            <MoreVertIcon color="primary" /> then{' '}
                            <AddToHomeScreenIcon color="primary" />
                            add to home screen to install on your mobile device.
                        </DialogContentText>
                    </DialogContent>
                ) : (
                    <Container sx={{ p: 2 }} spacing={2}>
                        {userInterface === 'browser' ? (
                            <DialogTitle id="alert-dialog-title">
                                Install to Subscribe!
                            </DialogTitle>
                        ) : (
                            <DialogTitle id="alert-dialog-title">
                                Update to Subscribe!
                            </DialogTitle>
                        )}
                        {userInterface === 'browser' ? (
                            <DialogContentText sx={{ p: 2 }}>
                                To get the best experience from the Kuronga App
                                tap to install it on your mobile.
                            </DialogContentText>
                        ) : (
                            <DialogContentText sx={{ p: 2 }}>
                                Looks like your OS is out of date. Try updating
                                it. On Your iPhone or iPad you need to be
                                running iOS 16.4 or later to receive
                                notifications.
                            </DialogContentText>
                        )}
                        {userInterface === 'browser' && (
                            <DialogContentText sx={{ p: 2 }}>
                                If you encounter this message on your computer
                                try updating your browser or switch to Chrome to
                                get the best experience.
                            </DialogContentText>
                        )}

                        {userInterface === 'browser' && (
                            <DialogActions spacing={2}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleInstall}
                                    fullWidth
                                >
                                    Install
                                </Button>
                            </DialogActions>
                        )}
                    </Container>
                )}
            </Dialog>
        </>
    );
}
