import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Badge,
    Tooltip,
    Avatar,
} from '@mui/material';

import { Link, withRouter } from 'react-router-dom';

import AccountCircle from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MoreIcon from '@mui/icons-material/MoreVert';

import useStyles from './styles.js';
import FullMenu from './FullMenu';
import { useAuth } from '../../contexts/AuthContext.js';
import NotificationMenu from '../Notifications/NotificationMenu.jsx';
import GetStarted from '../GetStarted/GetStarted.jsx';
import Logo from '../Logo/Logo.jsx';

function Header(props) {
    const classes = useStyles();

    useEffect(() => {
        console.log('Header Rendered');
    }, []);

    const { currentUser, userType } = useAuth();

    const cart = useSelector((state) => state.cart);
    const { cartItems } = cart;

    const [anchorEl, setAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuId = 'primary-search-account-menu';

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Logo />
                    <Link to="/" className={classes.brand}>
                        <Tooltip title="Home" arrow>
                            <Typography
                                variant="h4"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'none', sm: 'block' } }}
                            >
                                {process.env.REACT_APP_BRAND}
                            </Typography>
                        </Tooltip>
                    </Link>

                    {/* <Box
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                        className={classes.Search}
                    >
                        <div className={classes.SearchIconWrapper}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            className={classes.StyledInputBase}
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Box> */}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box>
                        <GetStarted />
                    </Box>
                    {currentUser && userType && (
                        <Box sx={{ ml: 1 }}>
                            <NotificationMenu
                                currentUser={currentUser}
                                userType={userType}
                            />
                        </Box>
                    )}
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton
                            size="large"
                            aria-label="shopping cart"
                            color="inherit"
                            onClick={() => {
                                props.history.push('/cart');
                                handleMenuClose();
                            }}
                        >
                            {userType && userType.isTrader && (
                                <Badge
                                    badgeContent={cartItems.length}
                                    color="error"
                                >
                                    <ShoppingCartIcon />
                                </Badge>
                            )}
                        </IconButton>

                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="open menu"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>

            <FullMenu
                props={props}
                anchorEl={anchorEl}
                menuId={menuId}
                isMenuOpen={isMenuOpen}
                handleMenuClose={handleMenuClose}
            />
        </Box>
    );
}

export default withRouter(Header);
