import { Box, Typography } from '@mui/material';
import React from 'react';

import { makeStyles } from '@mui/styles';
import theme from '../../appTheme';

const useStyles = makeStyles(() => ({
    alertDanger: {
        padding: theme.spacing(1),
        border: '0.1rem, solid transparent',
        borderRadius: '0.5rem',
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.contrastText,
    },
    alertInfo: {
        padding: theme.spacing(1),
        border: '0.1rem, solid transparent',
        borderRadius: '0.5rem',
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.light,
    },

    alertSuccess: {
        padding: theme.spacing(1),
        border: '0.1rem, solid transparent',
        borderRadius: '0.5rem',
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.light,
    },
    messageBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
}));

export default function SmallMessageBox(props) {
    const classes = useStyles();
    return (
        <Box className={classes.messageBox}>
            {props.variant === 'success' ? (
                <Box className={classes.alertSuccess}>
                    <Typography>{props.children}</Typography>
                </Box>
            ) : props.variant === 'danger' ? (
                <Box className={classes.alertDanger}>
                    <Typography>{props.children}</Typography>
                </Box>
            ) : (
                <Box className={classes.alertInfo}>
                    <Typography>{props.children}</Typography>
                </Box>
            )}
        </Box>
    );
}
