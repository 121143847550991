import {
    CURRENCIES_FAIL,
    CURRENCIES_REQUEST,
    CURRENCIES_RESET,
    CURRENCIES_SUCCESS,
} from '../constants/currenciesConstants';

export const latestCurrenciesReducer = (state = {}, action) => {
    switch (action.type) {
        case CURRENCIES_REQUEST:
            return { loading: true };
        case CURRENCIES_SUCCESS:
            return { loading: false, currencies: action.payload };
        case CURRENCIES_FAIL:
            return { loading: false, error: action.payload };
        case CURRENCIES_RESET:
            return {};
        default:
            return state;
    }
};
