export const UPDATE_GRADING_FEEDBACK_REQUEST =
    'UPDATE_GRADING_FEEDBACK_REQUEST';
export const UPDATE_GRADING_FEEDBACK_SUCCESS =
    'UPDATE_GRADING_FEEDBACK_SUCCESS';
export const UPDATE_GRADING_FEEDBACK_FAIL = 'UPDATE_GRADING_FEEDBACK_FAIL';
export const UPDATE_GRADING_FEEDBACK_RESET = 'UPDATE_GRADING_FEEDBACK_RESET';

export const GET_GRADING_FEEDBACK_REQUEST = 'GET_GRADING_FEEDBACK_REQUEST';
export const GET_GRADING_FEEDBACK_SUCCESS = 'GET_GRADING_FEEDBACK_SUCCESS';
export const GET_GRADING_FEEDBACK_FAIL = 'GET_GRADING_FEEDBACK_FAIL';
export const GET_GRADING_FEEDBACK_RESET = 'GET_GRADING_FEEDBACK_RESET';

export const REQUEST_MORE_PHOTOS_REQUEST = 'REQUEST_MORE_PHOTOS_REQUEST';
export const REQUEST_MORE_PHOTOS_SUCCESS = 'REQUEST_MORE_PHOTOS_SUCCESS';
export const REQUEST_MORE_PHOTOS_FAIL = 'REQUEST_MORE_PHOTOS_FAIL';
export const REQUEST_MORE_PHOTOS_RESET = 'REQUEST_MORE_PHOTOS_RESET';
