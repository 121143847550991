import React from 'react';
import { Route } from 'react-router-dom';

export default function RenderRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                props.location.pathname === '/signin' ? null : props.location
                      .pathname === '/signup' ? null : props.location
                      .pathname === '/register' ? null : props.location
                      .pathname === '/password-reset' ? null : props.location
                      .pathname === '/grading' ? null : (
                    <Component {...props}></Component>
                )
            }
        ></Route>
    );
}
