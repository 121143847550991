//import { createTheme } from '@mui/material/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { createTheme } from '@mui/material/styles';
const breakpoints = createBreakpoints({});

const theme = createTheme({
    palette: {
        primary: {
            main: '#069151',
        },
        secondary: {
            main: '#FEBB25',
        },
        footer: {
            main: '#2B313D',
            // main: '#151719',
            // main: '#33363a',
        },
    },

    components: {
        MuiInput: {
            styleOverrides: {
                root: {
                    variant: 'outlined',
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    [breakpoints.down('xs')]: {
                        orientation: 'vertical',
                    },
                },
            },
        },
    },
});

export default theme;
