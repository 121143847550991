export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';
export const PRODUCT_LIST_RESET = 'PRODUCT_LIST_RESET';

export const PRODUCT_LIST_FILTER_REQUEST = 'PRODUCT_LIST_FILTER_REQUEST';
export const PRODUCT_LIST_FILTER = 'PRODUCT_LIST_FILTER';
export const PRODUCT_LIST_CLEAR = 'PRODUCT_LIST_CLEAR';

export const USER_PRODUCT_LIST_REQUEST = 'USER_PRODUCT_LIST_REQUEST';
export const USER_PRODUCT_LIST_SUCCESS = 'USER_PRODUCT_LIST_SUCCESS';
export const USER_PRODUCT_LIST_FAIL = 'USER_PRODUCT_LIST_FAIL';
export const USER_PRODUCT_LIST_RESET = 'USER_PRODUCT_LIST_RESET';
