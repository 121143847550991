import {
  PRODUCT_TYPES_FAIL,
  PRODUCT_TYPES_REQUEST,
  PRODUCT_TYPES_SUCCESS,
} from '../constants/cropTypeConstants';

export const cropTypesListReducer = (state = { cropTypes: [] }, action) => {
  switch (action.type) {
    case PRODUCT_TYPES_REQUEST:
      return { loading: true };
    case PRODUCT_TYPES_SUCCESS:
      return { loading: false, cropTypes: action.payload };
    case PRODUCT_TYPES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
