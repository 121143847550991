import {
    USER_ADDRESS_MAP_CONFIRM,
    USER_ADDRESS_MAP_RESET,
    OTHER_USER_LOCATION_ADDRESS_FAIL,
    OTHER_USER_LOCATION_ADDRESS_REQUEST,
    OTHER_USER_LOCATION_ADDRESS_RESET,
    OTHER_USER_LOCATION_ADDRESS_SUCCESS,
    USER_LOCATION_ADDRESS_FAIL,
    USER_LOCATION_ADDRESS_REQUEST,
    USER_LOCATION_ADDRESS_RESET,
    USER_LOCATION_ADDRESS_SUCCESS,
    USER_MANUAL_PROFILE_CONFIRM,
    USER_MANUAL_PROFILE_RESET,
    USER_PROFILE_FAIL,
    USER_PROFILE_REQUEST,
    USER_PROFILE_RESET,
    USER_PROFILE_SUCCESS,
    USER_VALIDATE_FAIL,
    USER_VALIDATE_REQUEST,
    USER_VALIDATE_SUCCESS,
    USER_REQUEST_FAIL,
    USER_REQUEST_REQUEST,
    USER_REQUEST_SUCCESS,
    UPDATE_USER_PROFILE_REQUEST,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_FAIL,
    UPDATE_USER_PROFILE_RESET,
    OTHER_USER_PROFILE_REQUEST,
    OTHER_USER_PROFILE_SUCCESS,
    OTHER_USER_PROFILE_FAIL,
    OTHER_USER_PROFILE_RESET,
    USER_VALIDATE_RESET,
    USER_SIGNOUT_REQUEST,
    USER_SIGNOUT_SUCCESS,
    USER_SIGNOUT_FAIL,
    USER_SIGNOUT_RESET,
    USER_MEETINGS_REQUEST,
    USER_MEETINGS_SUCCESS,
    USER_MEETINGS_FAIL,
} from '../constants/userConstants';

export const userAddressMapReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_ADDRESS_MAP_CONFIRM:
            return { address: action.payload };
        case USER_ADDRESS_MAP_RESET:
            return {};
        default:
            return state;
    }
};

export const manualProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_MANUAL_PROFILE_CONFIRM:
            console.log('manualProfileReducer');
            return { profile: action.payload };
        case USER_MANUAL_PROFILE_RESET:
            return {};
        default:
            return state;
    }
};

export const userProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_PROFILE_REQUEST:
            return { loading: true };
        case USER_PROFILE_SUCCESS:
            return { loading: false, profile: action.payload };
        case USER_PROFILE_FAIL:
            return { loading: false, error: action.payload };
        case USER_PROFILE_RESET:
            return {};
        default:
            return state;
    }
};

export const otherUserProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case OTHER_USER_PROFILE_REQUEST:
            return { loading: true };
        case OTHER_USER_PROFILE_SUCCESS:
            return { loading: false, user: action.payload };
        case OTHER_USER_PROFILE_FAIL:
            return { loading: false, error: action.payload };
        case OTHER_USER_PROFILE_RESET:
            return {};
        default:
            return state;
    }
};

export const userLocationAddressReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOCATION_ADDRESS_REQUEST:
            return { loading: true };
        case USER_LOCATION_ADDRESS_SUCCESS:
            return { loading: false, userAddress: action.payload };
        case USER_LOCATION_ADDRESS_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOCATION_ADDRESS_RESET:
            return {};
        default:
            return state;
    }
};

export const otherUserLocationAddressReducer = (state = {}, action) => {
    switch (action.type) {
        case OTHER_USER_LOCATION_ADDRESS_REQUEST:
            return { loading: true };
        case OTHER_USER_LOCATION_ADDRESS_SUCCESS:
            return { loading: false, otherAddress: action.payload };
        case OTHER_USER_LOCATION_ADDRESS_FAIL:
            return { loading: false, error: action.payload };
        case OTHER_USER_LOCATION_ADDRESS_RESET:
            return {};
        default:
            return state;
    }
};

export const userSignupRequestReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REQUEST_REQUEST:
            return { loading: true };
        case USER_REQUEST_SUCCESS:
            return {
                loading: false,
                success: true,
                userRequest: action.payload,
            };
        case USER_REQUEST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const userValidateReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_VALIDATE_REQUEST:
            return { loading: true };
        case USER_VALIDATE_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_VALIDATE_FAIL:
            return { loading: false, error: action.payload };
        case USER_VALIDATE_RESET:
            return {};
        default:
            return state;
    }
};

export const userUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_USER_PROFILE_REQUEST:
            return { loading: true };
        case UPDATE_USER_PROFILE_SUCCESS:
            return { loading: false, success: true };
        case UPDATE_USER_PROFILE_FAIL:
            return { loading: false, error: action.payload };
        case UPDATE_USER_PROFILE_RESET:
            return {};
        default:
            return state;
    }
};

export const userSignoutReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_SIGNOUT_REQUEST:
            return { loading: true };
        case USER_SIGNOUT_SUCCESS:
            return { loading: false, success: true };
        case USER_SIGNOUT_FAIL:
            return { loading: false, error: action.payload };
        case USER_SIGNOUT_RESET:
            return {};
        default:
            return state;
    }
};

export const userMeetingsReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_MEETINGS_REQUEST:
            return { loading: true };
        case USER_MEETINGS_SUCCESS:
            return {
                loading: false,
                success: true,
                userRequest: action.payload,
            };
        case USER_MEETINGS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
