export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_SAVE_PERSONAL_DETAILS = 'CART_SAVE_PERSONAL_DETAILS';
export const CART_SAVE_SHIPPING_ADDRESS = 'CART_SAVE_SHIPPING_ADDRESS';
export const CART_SAVE_PERSONAL_DISPATCH_DETAILS =
    'CART_SAVE_PERSONAL_DISPATCH_DETAILS';
export const CART_SAVE_DISPATCH_ADDRESS = 'CART_SAVE_DISPATCH_ADDRESS';
export const CART_SAVE_SHIPPING_METHOD = 'CART_SAVE_SHIPPING_METHOD';
export const CART_SAVE_PAYMENT_METHOD = 'CART_SAVE_PAYMENT_METHOD';
export const CART_EMPTY = 'CART_EMPTY';
export const CART_ADD_ITEM_FAIL = 'CART_ADD_ITEM_FAIL';
export const CART_ADD_STANDING_ORDER = 'CART_ADD_STANDING_ORDER';
export const CART_EMPTY_STANDING_ORDER = 'CART_EMPTY_STANDING_ORDER';
