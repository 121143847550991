import React, { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
} from '@mui/material';
import CloseButton from '../Buttons/CloseButton';
import UserProgress from './UserProgress';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

import IosShareIcon from '@mui/icons-material/IosShare';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import { useSelector } from 'react-redux';

function getPWADisplayMode() {
    const isStandalone = window.matchMedia(
        '(display-mode: standalone)'
    ).matches;
    if (document.referrer.startsWith('android-app://')) {
        return 'twa';
    } else if (navigator.standalone || isStandalone) {
        return 'standalone';
    }
    return 'browser';
}

export default function GetStarted() {
    let history = useHistory();

    const { currentUser, userType } = useAuth();
    const setupConfig = useSelector((state) => state.setupConfig);
    const { setup } = setupConfig;

    const [open, setOpen] = useState(false);
    const [viewInstall, setViewInstall] = useState(false);
    const [userInterface, setUserInterface] = useState('browser');
    const [badgeCount, setBadgeCount] = useState(0);

    useEffect(() => {
        const userView = getPWADisplayMode();
        setUserInterface(userView);
    }, []);

    useEffect(() => {
        var count = 0;
        if (userInterface === 'browser') {
            count = count + 1;
        }
        if (setup) {
            if (!setup.profileComplete) {
                count = count + 1;
            }
            if (!setup.addressComplete) {
                count = count + 1;
            }
            if (!setup.listingComplete) {
                count = count + 1;
            }
        }
        setBadgeCount(count);
    }, [userInterface, setup]);

    const handleGetStarted = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setViewInstall(false);
    };
    const handleSignup = () => {
        history.push('/signup');
    };
    const handleInstall = () => {
        setViewInstall(true);
    };

    return (
        <>
            <Badge badgeContent={badgeCount} color="error">
                <Chip
                    variant="contained"
                    color="secondary"
                    label="Get Started!"
                    onClick={handleGetStarted}
                />
            </Badge>

            <Dialog open={open} onClose={handleClose}>
                {viewInstall ? (
                    <DialogContent>
                        <DialogTitle id="alert-dialog-title">
                            How to install
                        </DialogTitle>
                        <DialogContentText sx={{ p: 2 }}>
                            In iOS tap share <IosShareIcon color="primary" />{' '}
                            and then <AddBoxOutlinedIcon color="primary" /> add
                            to home to install on your iPhone or iPad. Make sure
                            you are running iOS 16.4 or later.
                        </DialogContentText>
                        <DialogContentText sx={{ p: 2 }}>
                            In Android tap your browser menu icon{' '}
                            <MoreVertIcon color="primary" /> then{' '}
                            <AddToHomeScreenIcon color="primary" />
                            add to home screen to install on your mobile device.
                        </DialogContentText>
                    </DialogContent>
                ) : (
                    <Container sx={{ p: 2 }} spacing={2}>
                        <DialogActions>
                            <CloseButton handleClose={handleClose} />
                        </DialogActions>
                        <DialogTitle id="alert-dialog-title">
                            {currentUser && userType
                                ? 'Complete Your Setup!'
                                : 'Welcome!'}
                        </DialogTitle>
                        {userInterface === 'browser' && (
                            <DialogContentText sx={{ p: 2 }}>
                                To get the best experience from the Kuronga App
                                tap to install it on your mobile. If you would
                                like to receive notificaiton updates in iOS this
                                is essential!
                            </DialogContentText>
                        )}
                        {userInterface === 'browser' && (
                            <DialogActions spacing={2}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleInstall}
                                    fullWidth
                                >
                                    Install
                                </Button>
                            </DialogActions>
                        )}
                        {userInterface === 'browser' && (
                            <Divider sx={{ m: 1, mt: 4 }} />
                        )}
                        <DialogContentText sx={{ p: 2 }}>
                            {currentUser && userType
                                ? `Thanks for signing up for the Kuronga platform.
                            Let's you get started.`
                                : 'Tap Signup to create an account'}
                        </DialogContentText>
                        {currentUser && userType ? (
                            <UserProgress setOpen={setOpen} />
                        ) : (
                            <DialogActions spacing={2}>
                                <Button
                                    variant="outlined"
                                    onClick={handleSignup}
                                    fullWidth
                                >
                                    Signup
                                </Button>
                            </DialogActions>
                        )}
                    </Container>
                )}
            </Dialog>
        </>
    );
}
