import { dotw } from '../constants/timeConstants';

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function twoDigits(value) {
    return value.toLocaleString('en-GB', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });
}

export function getShortDate(timeMilli) {
    const meetDate = new Date(timeMilli);

    const year = meetDate.getFullYear();
    const month = months[meetDate.getMonth()];
    const date = meetDate.getDate();

    const day = daysOfWeek[meetDate.getDay()];

    const dateString = day + ' ' + date + ' of ' + month + ' ' + year;

    return dateString;
}

export function getTodaysDate() {
    const today = new Date();

    const year = today.getFullYear();
    const month = months[today.getMonth()];
    const day = today.getDate();

    const dateString = day + '/' + month + '/' + year;

    return dateString;
}

export function getDashedDate(timeMilli) {
    const date = new Date(timeMilli);

    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();

    const dateString = day + '-' + month + '-' + year;

    return dateString;
}

export function getDateAndTime(timeMilli) {
    const shipDate = new Date(timeMilli);

    const year = shipDate.getFullYear();
    const month = months[shipDate.getMonth()];
    const day = shipDate.getDate();
    const hours = shipDate.getHours();
    const mins = shipDate.getMinutes();

    const dateString =
        day + '/' + month + '/' + year + '  ' + hours + ':' + mins;

    return dateString;
}

export function getDateTimeRange(cartItems) {
    let dateTimeRange = {
        maxDateTime: cartItems[0].endDate,
        minDateTime: cartItems[0].startDate,
    };

    for (const item of cartItems) {
        const thisMinDateTime = item.startDate;
        const thisMaxDateTime = item.endDate;

        if (thisMinDateTime > dateTimeRange.minDateTime) {
            dateTimeRange.minDateTime = thisMinDateTime;
        }

        if (thisMaxDateTime < dateTimeRange.maxDateTime) {
            dateTimeRange.maxDateTime = thisMaxDateTime;
        }
    }

    const currentTime = Date.now();

    if (dateTimeRange.minDateTime < currentTime) {
        dateTimeRange.minDateTime = currentTime;
    }

    return dateTimeRange;
}

export function getTooltipDate(timeMilli) {
    const meetDate = new Date(timeMilli);

    const year = meetDate.getFullYear();
    const month = months[meetDate.getMonth()];
    const date = meetDate.getDate();

    const day = daysOfWeek[meetDate.getDay()];

    const dateString = day + ' ' + date + ' of ' + month + ' ' + year;

    return dateString;
}

export function getHoursMins(timeMilli) {
    const date = new Date(timeMilli);

    return date.toLocaleString('en-GB', { hour: 'numeric', minute: 'numeric' });
}

export function friendlyTimeStamp(timestamp) {
    const yesterdayText = 'Yesterday';
    const todayText = 'Today';
    const tomorrowText = 'Tomorrow';
    const language = 'en-GB';
    var lastSevenDaysOpt = { weekday: 'long' };
    var nextSevenDaysOpt = { month: 'short', day: 'numeric' };
    var sameYearOpt = { month: 'short', day: 'numeric' };
    var farDateOpt = { year: 'numeric', month: 'short', day: 'numeric' };

    var dt = new Date(timestamp);
    var date = dt.getDate();
    var diffDays = new Date().getDate() - date;
    var diffMonths = new Date().getMonth() - dt.getMonth();
    var diffYears = new Date().getFullYear() - dt.getFullYear();

    var isToday = diffYears === 0 && diffMonths === 0 && diffDays === 0;
    var isYesterday = diffYears === 0 && diffMonths === 0 && diffDays === 1;
    var isTomorrow = diffYears === 0 && diffMonths === 0 && diffDays === -1;
    var isLastSevenDays =
        diffYears === 0 && diffMonths === 0 && diffDays > 1 && diffDays < 7;
    var isNextSevenDays =
        diffYears === 0 && diffMonths === 0 && diffDays < -1 && diffDays > -7;
    var isSameYear = diffYears === 0;

    if (isToday) {
        return todayText;
    } else if (isYesterday) {
        return yesterdayText;
    } else if (isTomorrow) {
        return tomorrowText;
    } else if (isLastSevenDays) {
        return dt.toLocaleString(language, lastSevenDaysOpt);
    } else if (isNextSevenDays) {
        return dt.toLocaleString(language, nextSevenDaysOpt);
    } else if (isSameYear) {
        return dt.toLocaleString(language, sameYearOpt);
    } else {
        return dt.toLocaleString(language, farDateOpt);
    }
}

export function getMessageDate(timeMilli) {
    const dateString =
        friendlyTimeStamp(timeMilli) + ' @ ' + getHoursMins(timeMilli);

    return dateString;
}

export function getNotificationDate(timeMilli) {
    const timeNumber = Number(timeMilli);
    const dateString =
        friendlyTimeStamp(timeNumber) + ' @ ' + getHoursMins(timeNumber);
    return dateString;
}

export function formatDotw(days) {
    return days.map((day) => dotw.find(({ label }) => label === day));
}

export function getAvailability(startDate, endDate) {
    return friendlyTimeStamp(startDate) + ' - ' + friendlyTimeStamp(endDate);
}

export function dateInPast(timeMilli) {
    const meetDate = new Date(timeMilli);
    var dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);

    var pastDate = false;

    if (meetDate < dateNow) {
        pastDate = true;
    }

    return pastDate;
}
